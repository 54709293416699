import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
//import querystring from 'querystring'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import store from '@/store'

export default {
  base() {
    let api = Api()
    api.defaults.baseURL = FwEnvConfig.apiUrlNotifications
    return api
  },

  async all(filterType, application = null, query = null, tags = [], limit = 30, page = 1) {
    let params = { limit: limit, page: page }

    if (filterType) {
      params.type = filterType
    }

    // Check attributes
    if (application && application != null && application != 'null') {
      params.application = application
    }
    if (tags.length > 0) {
      params.tags = tags
    }
    if (query) {
      params.query = query
    }
    let data = {
      params: params,
      /*paramsSerializer: params => {
        return querystring.stringify(params)
      },*/
    }

    const response = await this.base().get('/v2/notifications', data)
    return response.data
  },
  async allUnread() {
    const response = await this.all('unread')
    return response.notifications
  },
  async get(key, ignoreWS = false) {
    let data
    if (ignoreWS) {
      data = {
        params: { ignore_connection_id: store.state.socket.connectionId },
      }
    }

    const response = await this.base().get(`/v1/notification/${key}`, data)
    return response.data
  },

  async update(key, action) {
    const data = {
      action: action,
      ignore_connection_id: store.state.socket.connectionId,
    }

    const response = await this.base().post(`/v1/notification/${key}`, data)
    return response.data
  },
  async updateBulk(keys = [], action) {
    const data = {
      action: action,
      keys: keys,
    }
    /*
     POST para <notifications_url>/v1/notifications
     payload: {"keys": ["1", "2"], "action": "read" | "archive"}
    */
    const response = await this.base().post(`/v1/notifications`, data)
    return response.data
  },
  async configurations() {
    const response = await this.base().get('/v1/configurations')
    return response.data
  },
  async updateConfigurations(keys) {
    const response = await this.base().post('/v1/configurations', keys)
    return response.data
  },

  subscriptionInboxRefresh(notifications) {
    const cls = {
      notifications: notifications,

      async WSMessages(messages) {
        if (messages.readNotification) {
          for (let message of messages.readNotification) {
            for (let notification of cls.notifications) {
              if (notification.key === message.key) {
                notification.unread = false
              }
            }
          }
        }
        if (messages.newNotification) {
          notifications.unshift(...messages.newNotification)
        }
      },

      destroy() {
        store.commit('unsubscribeWS', { code: 'notifications', name: 'notifications' })
      },
    }

    store.commit('subscribeWS', { code: 'notifications', name: 'notifications', callback: cls.WSMessages })
    return cls
  },
}
