<template>
  <div class="w-full h-full flex justify-between">
    <!-- Sidebar -->
    <div v-if="!isMobile && $slots.sidebar" class="w-full md:w-[28rem] h-full flex flex-col">
      <slot name="sidebar" />
    </div>

    <!-- Main -->
    <div class="flex-1 h-full border-gray-200" :class="{ 'border-l': !isMobile && $slots.sidebar }">
      <div class="flex flex-col h-full relative">
        <div class="w-full h-full flex flex-col justify-between" :class="[`bg-${bgColor}`]">
          <div class="flex-1 overflow-y-auto h-full relative p-5 lg:p-10 flex flex-col">
            <slot name="default" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FwLayoutMainContent',

  props: {
    bgColor: {
      type: String,
      default: 'bg-white',
    },
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    },
  },
}
</script>
