<template>
  <div class="h-full flex flex-col items-center justify-center">
    <div class="flex flex-col items-center gap-2 mb-5 justify-center">
      <fw-icon-box-open class="w-12 h-12" />
      <fw-heading size="h2">Criar novo repositório</fw-heading>
    </div>
    <form @submit.prevent="addBucket">
      <b-field label="Nome do repositório">
        <b-input
          v-model="title"
          placeholder="Indique um nome"
          expanded
          custom-class="bg-gray-500 bg-opacity-20"
        ></b-input>
      </b-field>
      <b-field class="has-margin-top-medium is-flex is-flex-right">
        <b-button type="is-text" class="has-margin-right" @click="$emit('go-back')">
          Cancelar
        </b-button>
        <b-button :loading="loading" :disabled="!title" :class="{ 'is-primary': title }" @click="addBucket">
          Continuar
        </b-button>
      </b-field>
    </form>
  </div>
</template>

<script>
import ServiceBuckets from '@/fw-modules/fw-core-vue/buckets/services/ServiceBuckets'

export default {
  props: {
    applicationContext: Object,
  },

  data() {
    return {
      loading: false,
      title: null,
    }
  },

  methods: {
    async addBucket() {
      if (!this.title) return

      this.loading = true
      try {
        const key = await ServiceBuckets.addBucket(this.title, this.applicationContext)
        this.$router.push({ name: 'bucket', params: { key: key } })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
