<template>
  <div class="px-3 py-2 rounded-lg bg-gray-100 group hover:bg-white">
    <div>
      <v-clamp :max-lines="2" autoresize class="font-medium text-base">
        {{ item.title }}
      </v-clamp>
      <v-clamp v-if="item.description" :max-lines="3" autoresize class="text-sm text-gray-500">
        {{ item.description }}
      </v-clamp>
      <div v-if="item.space.team_name" class="text-xs font-semibold text-gray-500 mt-2">{{ item.space.team_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>
