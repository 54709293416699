<template>
  <div class="inline-flex items-center gap-1">
    <div
      class="font-semibold leading-5"
      :class="[{ 'my-1.5': !marginless, uppercase: uppercase }, customClass, color, `text-${size}`]"
    >
      <slot />
    </div>
    <div v-if="counter" class="text-gray-500 text-xs">{{ counter }}</div>
    <div v-if="loading || checkboxActive" class="w-5 h-5 -mt-0.5">
      <div v-if="loading"><fw-icon-loading class="w-5 h-5" /></div>
      <div v-if="checkboxActive"><fw-icon-check class="w-5 h-5 text-primary" /></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    marginless: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Boolean, String],
      default: 'sm',
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'text-gray-500',
    },
    customClass: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    afterLoadingChecked: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      checkboxActive: this.checked,
    }
  },
  watch: {
    loading(value, previous) {
      this.checkboxActive = false
      if (previous && !value && this.afterLoadingChecked) {
        this.checkboxActive = true
        setTimeout(() => {
          this.checkboxActive = false
        }, 3000)
      }
    },
  },
}
</script>
