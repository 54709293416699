<template>
  <fw-panel title="Permissões" subtitle="Utilizadores associados" :section="false" featured>
    <template v-if="allUsers.length" #toolbar>
      <fw-button type="link" @click.native="showAddForm = true">Adicionar</fw-button>
    </template>
    <div
      v-if="allUsers.length && bucket.type == 'unit_edition'"
      class="text-sm mb-5 text-gray-500 text-center max-w-prose mx-auto"
    >
      Todos os estudantes e docentes associados à disciplina têm acesso a este repositório. Esta autorização é feita
      automaticamente. Nesta página precisa apenas definir permissões para outros utilizadores fora deste contexto.
    </div>
    <div
      v-if="allUsers.length && bucket.type == 'class_edition'"
      class="text-sm mb-5 text-gray-500 text-center max-w-prose mx-auto"
    >
      Todos os estudantes e docentes associados à turma têm acesso a este repositório. Esta autorização é feita
      automaticamente. Nesta página precisa apenas definir permissões para outros utilizadores fora deste contexto.
    </div>
    <div v-if="allUsers.length" class="grid gap-2 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      <Permission v-for="user in allUsers" :key="user.key" :bucket="bucket" :user="user" @set-users="setUsers" />
    </div>
    <div v-else class="h-full flex flex-col items-center justify-center mx-auto max-w-prose">
      <fw-icon-people
        class="w-20 h-20 p-3 text-gray-400 bg-gray-300 bg-opacity-50 flex items-center justify-center rounded-full"
      />
      <div v-if="bucket.type == 'unit_edition'" class="text-gray-500 p-4 text-center">
        <div class="font-bold">
          Todos os estudantes e docentes associados à disciplina têm acesso a este repositório. Esta autorização é feita
          automaticamente.
        </div>
        <div class="text-sm">
          Ainda não existem outros utilizadores com permissões para este recurso.
        </div>
      </div>
      <div v-else-if="bucket.type == 'class_edition'" class="text-gray-500 p-4 text-center">
        <div class="font-bold">
          Todos os estudantes e docentes associados à turma têm acesso a este repositório. Esta autorização é feita
          automaticamente.
        </div>
        <div class="text-sm">
          Ainda não existem outros utilizadores com permissões para este recurso.
        </div>
      </div>
      <div v-else class="text-sm text-gray-500 p-4 text-center">
        Ainda não existem utilizadores com permissões para este recurso.
      </div>
      <div class="my-4">
        <fw-button type="link" @click.native="showAddForm = true">Adicionar novo</fw-button>
      </div>
    </div>
    <b-modal
      v-if="showAddForm"
      :can-cancel="true"
      trap-focus
      :active.sync="showAddForm"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="390"
      scroll="keep"
    >
      <div class="pt-3 pb-5">
        <div class="flex items-center justify-between mb-0 px-5 ">
          <fw-label>Adicionar</fw-label>
          <fw-button type="link" size="sm" @click.native="showAddForm = false">Fechar</fw-button>
        </div>

        <div class="flex items-end gap-x-3 mb-3 px-5">
          <fw-button
            v-for="filter in addPermissionTypes"
            :key="filter.key"
            :type="filter.key == addPermissionsType ? 'tab-active' : 'tab'"
            @click.native="selectPermissionFilter(filter.key)"
          >
            <v-clamp autoresize :max-lines="1">
              {{ filter.label }}
            </v-clamp>
          </fw-button>
        </div>

        <form
          v-if="
            addPermissionsType === 'user' ||
              (addPermissionsType === 'group' && addPermissionSelectedGroup !== null) ||
              (addPermissionsType === 'class_edition' && addPermissionSelectedClass !== null)
          "
          class="px-5"
          @submit.prevent="addUser"
        >
          <b-field v-if="errorMessage" class="notification is-danger has-padding">{{ errorMessage }}</b-field>
          <b-field v-if="addPermissionsType === 'user'" label="Email Institucional UC">
            <b-input v-model="email" placeholder="utilizador@uc.pt" expanded autofocus></b-input>
          </b-field>
          <div v-else-if="addPermissionsType === 'group' && addPermissionSelectedGroup !== null">
            {{ addPermissionSelectedGroup }}
          </div>
          <div
            v-else-if="addPermissionsType === 'class_edition' && addPermissionSelectedClass !== null"
            class="relative cursor-pointer mb-4"
            @click="addPermissionSelectedClass = null"
          >
            <RowClass :classobj="addPermissionSelectedClass" selector></RowClass>
          </div>
          <fw-label>Permissões</fw-label>
          <div class="flex flex-col gap-2 py-2">
            <b-field v-if="errorMessage" class="notification is-danger has-padding">{{ errorMessage }}</b-field>
            <!-- TODO -->
            <button v-show="false" class="border-gray-200 hover:shadow-md px-3 py-4 flex bg-white rounded-t">
              <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                />
              </svg>
              Ver os itens
            </button>

            <b-checkbox v-model="isManager" size="is-small">Gestor</b-checkbox>
            <b-checkbox v-if="!isManager" v-model="canAddItems" size="is-small">Adicionar itens</b-checkbox>
            <b-checkbox v-if="!isManager" v-model="canEditItems" size="is-small">Editar itens</b-checkbox>
            <b-checkbox v-if="!isManager" v-model="canDeleteItems" size="is-small">Remover itens</b-checkbox>
          </div>
        </form>
        <div v-else-if="addPermissionsType === 'class_edition'">
          <ClassSelectorList :max-height="maxModalHeight" @selected="classSelected"></ClassSelectorList>
        </div>
        <div v-else-if="addPermissionsType === 'group'">Group Selector</div>

        <div class="mt-5 px-5 flex justify-end items-center gap-2">
          <fw-button type="link-muted" @click.native="showAddForm = false">Cancelar</fw-button>
          <fw-button
            :type="!isReady ? 'disabled' : 'regular'"
            :disabled="!isReady"
            :class="{ 'is-primary': isReady }"
            @click.native="addUser"
          >
            Adicionar
          </fw-button>
        </div>
      </div>
    </b-modal>
  </fw-panel>
</template>

<script>
import ServiceBuckets from '@/fw-modules/fw-core-vue/buckets/services/ServiceBuckets'
import Permission from '@/fw-modules/fw-core-vue/buckets/components/blocks/Permission'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ClassSelectorList from '../modals/ClassSelectorList'
import RowClass from '@/fw-modules/fw-core-vue/buckets/components/cards/RowClass'

export default {
  components: {
    Permission,
    ClassSelectorList,
    RowClass,
  },

  props: {
    bucket: {
      type: Object,
      deep: true,
    },
  },

  data() {
    this.getPermissions()
    return {
      loading: false,
      email: null,
      isManager: false,
      canAddItems: false,
      canEditItems: false,
      canDeleteItems: false,
      users: [],
      users_auto: [],
      errorMessage: '',
      showAddForm: false,
      //Add Permissions Modal
      addPermissionsType: 'user',
      addPermissionSelectedClass: null,
      addPermissionSelectedGroup: null,
      addPermissionTypes: [
        {
          key: 'user',
          label: 'Utilizador',
        },
      ],
    }
  },

  computed: {
    allUsers() {
      return this.users.concat(this.users_auto).sort((a, b) => a.name.localeCompare(b.name))
    },
    maxModalHeight() {
      return window.innerHeight * 0.55
    },
    isReady() {
      if (this.addPermissionsType === 'user') {
        if (!this.email) return false

        const parts = this.email
          .toLocaleLowerCase()
          .trim()
          .split('@')
        return parts.length == 2
      } else if (this.addPermissionsType === 'class_edition') {
        return this.addPermissionSelectedClass !== null
      } else {
        return false
      }
    },
    userIsTeacher() {
      let roles = this.$store.getters.getUser?.roles ?? []
      return roles.includes('teacher')
    },
  },

  created() {
    if (this.userIsTeacher) {
      this.addPermissionTypes = [
        {
          key: 'user',
          label: 'Utilizador',
        },
        /*{
          key: 'class_edition',
          label: 'Turma',
        },*/
        /*{
          key: 'group',
          label: 'Grupo',
        },*/
      ]
    }
  },
  methods: {
    selectPermissionFilter(key) {
      this.addPermissionsType = key
      this.addPermissionSelectedClass = null
      this.email = ''
    },
    classSelected(classData) {
      this.addPermissionSelectedClass = classData
    },
    setUsers(users) {
      this.users = users
    },

    async getPermissions() {
      this.loading = true
      try {
        let result = await ServiceBuckets.getBucketPermissions_v2(this.bucket.key)
        this.users = result.manual
        this.users_auto = result.auto
      } finally {
        this.loading = false
      }
    },
    async addUser() {
      this.loading = true
      try {
        let data = {
          email: this.email,
          is_manager: this.isManager,
          add_items: this.canAddItems,
          edit_items: this.canEditItems,
          delete_items: this.canDeleteItems,
        }

        if (this.addPermissionsType === 'user') {
          data['email'] = this.email
        } else if (this.addPermissionsType === 'class_edition') {
          data.item_type = 'class_edition'
          data.item_key = this.addPermissionSelectedClass.key
        } else {
          this.loading = false
          return
        }

        console.log(data)

        const response = await ServiceBuckets.addBucketPermission(this.bucket.key, data)

        this.users = response.users
        this.errorMessage = ''
        this.email = ''
        this.isManager = false
        this.canAddItems = false
        this.canEditItems = false
        this.canDeleteItems = false
        //hide form
        this.showAddForm = false

        for (let user of this.users) {
          if (user.key === response.new_user_key) {
            this.$emit('add-chat-user', user)
            break
          }
        }
        this.$emit('sort-chat-users')
      } catch (error) {
        const errorKey = utils.errors(error).getFieldKey('email')
        if (!errorKey) {
          this.errorMessage = 'Ocorreu um erro, por favor tente novamente mais tarde'
        } else if (errorKey == 'ItemNotFound') {
          this.errorMessage = 'O email indicado não está registado.'
        } else if (errorKey == 'InvalidEmail') {
          this.errorMessage = 'O email não é válido.'
        } else if (errorKey == 'OwnerNotAllowed') {
          this.errorMessage =
            'Não precisa adicionar o seu endereço de email. Já tem acesso completo à gestão do bucket.'
        } else {
          this.errorMessage = `Não foi possível adicionar o email. Por favor, tente mais tarde.
            Caso o problema persista, contacte-nos através do nosso canal de suporte.`
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
