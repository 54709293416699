<template>
  <button
    :type="role"
    :class="[
      style,
      {
        'cursor-not-allowed': disabled === true || loading === true,
        'opacity-50': disabled === true && loading === false,
      },
    ]"
    :arial-label="label"
    :disabled="disabled || loading"
    class="relative flex-shrink-0"
  >
    <fw-icon-checkbox-solid
      v-if="checked"
      class="w-5 h-5 bg-white rounded-full text-primary flex-shrink-0"
      :class="{ 'absolute -top-1 -right-1': !checkBox }"
    />
    <fw-icon-checkbox-solid
      v-else-if="!checked && checkBox"
      class="w-5 h-5 bg-white rounded-full text-gray-300 flex-shrink-0"
    />
    <span v-if="$slots.icon" class="flex items-center" :class="{ 'gap-2.5': size != 'xs', 'gap-1': size == 'xs' }">
      <span class="flex-shrink-0">
        <slot name="icon" />
      </span>
      <span class="flex-1 text-left">
        <slot />
      </span>
    </span>
    <span v-else-if="icon || iconRight" class="flex-1 flex gap-2 items-center justify-between">
      <span><component :is="'fw-icon-' + icon" v-if="icon" class="w-5 h-5 flex-shrink-0"/></span>
      <span class="flex-1 text-left"><slot /></span>
      <span><component :is="'fw-icon-' + iconRight" v-if="iconRight" class="w-5 h-5 flex-shrink-0"/></span>
    </span>
    <slot v-else />
    <span
      v-if="loading"
      class="absolute top-0 left-0 w-full h-full flex items-center justify-center"
      :class="[style, { 'cursor-not-allowed': disabled === true || loading === true }]"
    >
      <fw-icon-loading class="w-5 h-5" />
    </span>
    <span v-if="counter" class="text-xs font-semibold text-gray-800 text-opacity-70 ml-2 mt-0.5">
      {{ counter }}/{{ counterTotal }}
    </span>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'simple',
    },
    label: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'sm',
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    wider: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    checkBox: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    rounded: {
      type: String,
      default: 'full',
    },
    icon: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    role: {
      type: String,
      default: 'button',
    },
    counter: {
      type: Number,
      default: null,
    },
    counterTotal: {
      type: Number,
      default: null,
    },
  },

  computed: {
    style() {
      const styleMap = {
        regular:
          'inline-flex flex items-center font-semibold text-white transition-colors duration-150 bg-primary active:bg-opacity-75 hover:bg-opacity-75 focus:outline-none focus:shadow-outline-gray',
        'regular-light':
          'inline-flex flex items-center font-semibold transition-colors duration-150 bg-gray-400 bg-opacity-20 active:bg-opacity-75 hover:bg-opacity-75 focus:outline-none focus:shadow-outline-gray',
        'basic-action':
          'inline-flex font-semibold items-center gap-4 min-w-10 hover:bg-gray-500 hover:bg-opacity-10 focus:outline-none',
        'dropdown-item': 'font-medium gap-4 min-w-10 text-left text-gray-600 focus:outline-none',
        simple:
          'inline-flex hover:text-opacity-70 font-semibold items-center justify-center text-sm focus:outline-none min-w-10 hover:bg-white hover:bg-opacity-30 focus:outline-none',
        tab:
          'inline-flex flex items-center px-0 py-2 font-medium text-gray-500 hover:text-black hover:border-black border-b border-gray-300 transition-colors duration-150 focus:outline-none',
        'tab-active':
          'inline-flex flex items-center px-0 py-2 text-primary font-bold border-b border-primary transition-colors duration-150 focus:outline-none',
        link:
          'inline-flex flex items-center text-primary font-bold transition-colors duration-150 border border-transparent focus:outline-none hover:opacity-80',
        'link-light':
          'inline-flex flex items-center text-gray-800 opacity-80 font-bold transition-colors duration-150 border border-transparent focus:outline-none',
        'link-white':
          'inline-flex flex items-center text-white opacity-80 font-bold transition-colors duration-150 border border-transparent focus:outline-none',
        'link-muted':
          'text-gray-500 font-bold transition-colors duration-150 border border-transparent focus:outline-none',
        'link-danger':
          'text-red-500 font-bold transition-colors duration-150 border border-transparent focus:outline-none',
        'link-primary':
          'text-primary font-bold transition-colors duration-150 border border-transparent focus:outline-none',
        'link-black':
          'text-gray-900 font-bold transition-colors duration-150 border border-transparent focus:outline-none',
        'link-orange':
          'text-yellow-600 font-bold transition-colors duration-150 border border-transparent focus:outline-none',
        active: 'inline-flex flex items-center bg-primary text-white focus:outline-none',
        black: 'bg-gray-900 font-semibold text-white focus:outline-none',
        primary: 'text-sm bg-primary bg-opacity-90 font-bold text-white focus:outline-none hover:opacity-75',
        orange: 'text-sm bg-yellow-600 bg-opacity-90 font-bold text-white focus:outline-none hover:opacity-75',
        danger: 'text-sm bg-red-400 font-bold text-white focus:outline-none hover:opacity-75',
        'border-light':
          'border border-gray-400 font-semibold text-gray-600 focus:outline-none transition-colors duration-150',
        'border-white':
          'border border-white font-semibold text-white focus:outline-none transition-colors duration-150',
        'border-black': 'border border-gray-900 font-semibold focus:outline-none transition-colors duration-150',
        'border-primary':
          'border border-primary font-semibold text-primary focus:outline-none transition-colors duration-150',
        'border-danger':
          'border hover:text-red-500 hover:border-red-200 border-red-300 font-semibold text-red-600 focus:outline-none transition-colors duration-150',
        'border-orange':
          'border hover:text-yellow-500 hover:border-yellow-500 border-yellow-600 font-semibold text-yellow-600 focus:outline-none transition-colors duration-150',
        white: 'bg-white font-semibold text-primary focus:outline-none',
        'glass-sm': 'bg-white shadow-sm focus:outline-none',
        glass: 'bg-white shadow-md focus:outline-none',
        'glass-xl': 'bg-white shadow-xl focus:outline-none',
        'white-gray': 'bg-white font-semibold text-gray-500 focus:outline-none',
        basic: 'focus:outline-none font-bold',
        transparent:
          'transparent font-semibold flex items-center justify-center text-sm focus:outline-none min-w-10 hover:bg-gray-400 hover:bg-opacity-10',
        'transparent-primary':
          'transparent font-semibold flex items-center justify-center text-sm focus:outline-none min-w-10 hover:bg-gray-400 hover:bg-opacity-10 text-primary',
        '2xlight':
          'bg-gray-100 bg-opacity-20 font-semibold flex items-center justify-center text-sm focus:outline-none min-w-10 hover:bg-gray-400 hover:bg-opacity-10',
        xlight:
          'bg-gray-100 bg-opacity-80 font-semibold flex items-center justify-center text-sm focus:outline-none min-w-10 hover:bg-gray-400 hover:bg-opacity-10',
        light:
          'bg-gray-300 bg-opacity-70 font-semibold flex items-center justify-center text-sm focus:outline-none min-w-10 hover:bg-opacity-90',
        'light-primary':
          'flex items-center gap-1 text-sm bg-primary bg-opacity-10 font-bold text-primary focus:outline-none hover:text-white min-w-10 hover:bg-opacity-90',
        'light-danger':
          'flex items-center gap-1 text-sm bg-red-600 bg-opacity-70 font-bold text-white focus:outline-none hover:text-white min-w-10 hover:bg-opacity-100',
        'light-yellow':
          'flex items-center gap-1 text-sm bg-yellow-600 bg-opacity-70 font-bold text-white focus:outline-none hover:text-white min-w-10 hover:bg-opacity-100',
        'light-orange':
          'flex items-center gap-1 text-sm bg-yellow-600 bg-opacity-70 font-bold text-white focus:outline-none hover:text-white min-w-10 hover:bg-opacity-100',
        disabled: 'text-sm bg-gray-200 font-semibold text-gray-400 focus:outline-none',
        dropdown:
          'px-2 py-1.5 bg-white bg-opacity-80 text-gray-500 font-bold flex items-center text-sm focus:outline-none border border-gray-150 shadow-sm',
      }
      const styleSizeMap = {
        xl: this.wider ? 'text-xl px-6 py-3' : 'text-xl px-3 py-3',
        lg: this.wider ? 'text-lg px-6 py-3' : 'text-lg px-3 py-3',
        md: this.wider ? 'text-base px-5 py-3' : 'text-base py-3 px-4',
        sm: this.wider ? 'text-sm px-5 py-2' : 'text-sm px-3 py-2',
        xs: this.wider ? 'text-xs px-4 py-1' : 'text-xs px-2 py-1',
      }

      let buttonClasses =
        (this.type != 'basic' ? styleSizeMap[this.size] : '') + ' ' + styleMap[this.type] + ' ' + this.customClass

      // Expanded
      if (this.expanded) {
        buttonClasses += ' w-full'
      }

      if (this.checkBox) {
        buttonClasses += ' justify-start gap-2 text-left'
      } else if (this.type === 'dropdown') {
        buttonClasses += ' justify-center'
      } else if (this.expanded) {
        if (this.icon || this.type === 'basic-action' || this.type === 'dropdown-item') {
          buttonClasses += ' text-left justify-start'
        } else {
          buttonClasses += ' text-center justify-center'
        }
        buttonClasses += ' items-center'
      }
      if (!this.paddingless && this.type === 'dropdown') {
        buttonClasses += ' pl-3'
      }

      if (this.paddingless) {
        buttonClasses += ' !p-0'
      }

      if (!['tab', 'tab-active', 'basic'].includes(this.type)) {
        if (this.rounded == 'full') {
          buttonClasses += ' rounded-lg'
        } else if (this.rounded == 'left') {
          buttonClasses += ' rounded-l-lg'
        } else if (this.rounded == 'right') {
          buttonClasses += ' rounded-r-lg'
        }
      }

      return buttonClasses
    },
  },
}
</script>
