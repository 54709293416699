<template>
  <fw-panel title="Permissões" subtitle="Utilizadores associados" :counter="users.length" :section="false">
    <template #toolbar>
      <fw-button v-if="!isTeacher" type="link" @click.native="showAddForm = true">Adicionar utilizador</fw-button>
      <b-dropdown v-else aria-role="list" position="is-bottom-left">
        <fw-button slot="trigger" type="link">Adicionar utilizador</fw-button>
        <b-dropdown-item aria-role="listitem" @click="choosePeople('chose_people')">
          <span>Pessoa individual</span>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="choosePeople('import_bulk')">
          <span>Lista de pessoas</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <div v-if="users.length" class="grid gap-2 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      <Permission v-for="user in users" :key="user.key" :meeting-key="meetingKey" :user="user" @set-users="setUsers" />
    </div>
    <div v-else class="h-full flex flex-col items-center justify-center mx-auto max-w-prose">
      <fw-icon-people
        class="w-20 h-20 p-3 text-gray-400 bg-gray-300 bg-opacity-50 flex items-center justify-center rounded-full"
      />
      <div class="text-sm text-gray-500 p-4 text-center">
        Ainda não existem utilizadores com permissões para este recurso.
      </div>
    </div>
    <b-modal
      v-if="showAddForm"
      :can-cancel="true"
      trap-focus
      :active.sync="showAddForm"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="360"
      scroll="keep"
    >
      <div class="px-5 pt-3 pb-5">
        <div class="flex items-center justify-between mb-4">
          <fw-label>Adicionar utilizador/a</fw-label>
          <fw-button type="link" size="sm" @click.native="showAddForm = false">Fechar</fw-button>
        </div>
        <form class="p-4" @submit.prevent="addUser">
          <b-field v-if="errorMessage" class="notification is-danger has-padding">{{ errorMessage }}</b-field>
          <b-field label="Email">
            <b-input v-model="email" placeholder="utilizador@uc.pt" expanded autofocus></b-input>
          </b-field>
          <fw-label>Permissões</fw-label>
          <div class="flex flex-col gap-2 py-2">
            <b-checkbox v-model="isManager" size="is-small">Gestor</b-checkbox>
            <b-checkbox v-if="!isManager" v-model="canAddUsers" size="is-small">Adicionar utilizadores</b-checkbox>
            <b-checkbox v-model="seeChatHistory" size="is-small">Permitir visualizar histórico do chat</b-checkbox>
          </div>
        </form>
        <div class="mt-5 flex justify-end items-center gap-2">
          <fw-button type="link-muted" @click.native="showAddForm = false">Cancelar</fw-button>
          <fw-button
            :type="!isReady ? 'disabled' : 'regular'"
            :disabled="!isReady"
            :class="{ 'is-primary': isReady }"
            @click.native="addUser"
          >
            Adicionar
          </fw-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      :active="modal != null"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChoosePeopleModal
        v-if="modal == 'chose_people'"
        :multiselect="true"
        :not-alowed-users="notAlowedUsers"
        :invite-people="false"
        :endpoint="searchEndpoint"
        @selected="selectedPeople"
        @close="closeModal"
      ></ChoosePeopleModal>
      <ModalImportBulkUsers
        v-if="modal == 'import_bulk'"
        :search-engine="bulkSearch"
        @selected="selectedPeople"
        @close="closeModal"
      ></ModalImportBulkUsers>
    </b-modal>
  </fw-panel>
</template>

<script>
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import Permission from '@/fw-modules/fw-core-vue/chats/components/blocks/Permission'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import ModalImportBulkUsers from '@/fw-modules/fw-core-vue/ui/components/modals/ModalImportBulkUsers'

export default {
  components: {
    Permission,
    ChoosePeopleModal,
    ModalImportBulkUsers,
  },

  props: {
    meetingKey: String,
  },

  data() {
    this.getPermissions()
    return {
      loading: false,
      email: null,
      isManager: false,
      canAddUsers: false,
      seeChatHistory: false,
      users: [],
      errorMessage: '',
      showAddForm: false,
      modal: null,
      currentSelectedPeople: [],
    }
  },

  computed: {
    isReady() {
      if (!this.email) return false

      const parts = this.email
        .toLocaleLowerCase()
        .trim()
        .split('@')
      return parts.length == 2
    },
    user() {
      return this.$store.getters.getUser
    },
    isTeacher() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('teacher'))
    },
    notAlowedUsers() {
      return this.users.map(el => el.key)
    },

    debugmode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
  },

  methods: {
    async searchEndpoint(params) {
      return await ServiceMeetings.searchUsers(this.meetingKey, params)
    },
    async bulkSearch(query) {
      query = query.split(',')
      return await ServiceMeetings.bulkSearchUsers(this.meetingKey, query)
    },
    closeModal() {
      this.modal = null
    },
    async selectedPeople(selection) {
      this.loading = true
      try {
        const response = await ServiceMeetings.addPermission(this.meetingKey, {
          user_keys: selection.map(el => el.key),
          is_manager: this.isManager,
          add_users: this.canAddUsers,
          see_chat_history: this.seeChatHistory,
        })

        this.users = response.users
        this.errorMessage = ''
        this.email = ''
        this.isManager = false
        this.canAddUsers = false
        this.seeChatHistory = false

        for (let user of this.users) {
          if (user.key === response.new_user_key) {
            this.$emit('add-chat-user', user)
            break
          }
        }
        this.$emit('sort-chat-users')
        this.modal = null
      } catch (error) {
        const errorKey = utils.errors(error).getFieldKey('email')
        if (!errorKey) {
          this.errorMessage = 'Ocorreu um erro, por favor tente novamente mais tarde'
        } else if (errorKey == 'ItemNotFound') {
          this.errorMessage = 'O email indicado não está registado.'
        } else if (errorKey == 'InvalidEmail') {
          this.errorMessage = 'O email não é válido.'
        } else if (errorKey == 'OwnerNotAllowed') {
          this.errorMessage = 'Não precisa adicionar o seu endereço de email. Já tem acesso completo à gestão ao grupo.'
        } else if (errorKey == 'PermissionAlreadyDefined') {
          this.errorMessage = 'O email indicado já foi adicionado.'
        } else {
          this.errorMessage = `Não foi possível adicionar o(s) utilizador(es). Por favor, tente mais tarde.
            Caso o problema persista, contacte-nos através do nosso canal de suporte.`
        }
      } finally {
        this.loading = false
      }
    },
    choosePeople(type) {
      this.modal = type //import_bulk || chose_people
    },
    setUsers(users) {
      this.users = users
    },

    async getPermissions() {
      this.loading = true
      try {
        this.users = await ServiceMeetings.getPermissions(this.meetingKey)
      } finally {
        this.loading = false
      }
    },
    async addUser() {
      this.loading = true
      try {
        const response = await ServiceMeetings.addPermission(this.meetingKey, {
          email: this.email,
          is_manager: this.isManager,
          add_users: this.canAddUsers,
          see_chat_history: this.seeChatHistory,
        })

        this.users = response.users
        this.errorMessage = ''
        this.email = ''
        this.isManager = false
        this.canAddUsers = false
        this.seeChatHistory = false

        for (let user of this.users) {
          if (user.key === response.new_user_key) {
            this.$emit('add-chat-user', user)
            break
          }
        }
        this.$emit('sort-chat-users')
        this.showAddForm = false
      } catch (error) {
        const errorKey = utils.errors(error).getFieldKey('email')
        if (!errorKey) {
          this.errorMessage = 'Ocorreu um erro, por favor tente novamente mais tarde'
        } else if (errorKey == 'ItemNotFound') {
          this.errorMessage = 'O email indicado não está registado.'
        } else if (errorKey == 'InvalidEmail') {
          this.errorMessage = 'O email não é válido.'
        } else if (errorKey == 'OwnerNotAllowed') {
          this.errorMessage = 'Não precisa adicionar o seu endereço de email. Já tem acesso completo à gestão ao grupo.'
        } else if (errorKey == 'PermissionAlreadyDefined') {
          this.errorMessage = 'O email indicado já foi adicionado.'
        } else {
          this.errorMessage = `Não foi possível adicionar o email. Por favor, tente mais tarde.
            Caso o problema persista, contacte-nos através do nosso canal de suporte.`
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
