import isEmpty from 'lodash/isEmpty'
import split from 'lodash/split'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  isLoggedIn: state => !!state.session.token,

  getToken: state => state.session.token,

  isPlugin: () => !!localStorage.getItem('session.isPlugin'),

  getUser(state) {
    let user = state.session.user
    if (!user || isEmpty(user)) {
      user = JSON.parse(localStorage.getItem('session.user'))
      if (!user || isEmpty(user)) return {}
    }

    // Cria atributos adicionais
    user.first_name = user.name ? split(user.name, ' ')[0] : user.name

    // Utilities
    user.getPublicToken = async function() {
      if (this.token !== undefined) return this.token

      // Avoid calling self user endpoint multiple times
      while (this.fetchingToken) await utils.sleep(50)
      if (this.token !== undefined) return this.token
      this.fetchingToken = true

      try {
        let userData = await ServiceAuth.getSelfUser()
        if (userData.token === undefined) userData.token = null
        localStorage.setItem('session.user', JSON.stringify(userData))
        // TODO set userData into state.session.user
        this.token = userData.token
      } catch (error) {
        console.error('Failed to get self details')
      }

      if (this.token === undefined) this.token = null
      this.fetchingToken = false
      return this.token
    }

    return user
  },

  getLocalUser(state) {
    return isEmpty(state.session.user) ? JSON.parse(localStorage.getItem('session.user')) : state.session.user
  },

  getApps(state) {
    return state.session.apps ?? {}
  },

  getUnreadNotifications(state) {
    return state.session.unreadNotifications ?? []
  },

  getUnreadNotificationsOfType(state) {
    //let self = this;
    return function(type) {
      let notifications = state.session.unreadNotifications ?? []
      return notifications.filter(notification => notification.type === type)
    }
  },

  getUnreadNotificationsCount(state) {
    return state.session.unreadNotificationsCount ?? 0
  },

  getUnreadChatMessages(state) {
    return state.session.unreadChatMessages
  },

  getUnreadChatMessagesCount(state) {
    return state.session.unreadChatMessagesCount
  },

  getContext(state) {
    return state.session.context
  },

  getDirtyData(state) {
    return state.dirtyData
  },

  getConnectedUsers(state) {
    return state.connectedUsers
  },

  getUserPermissions(state) {
    return state.session.permissions
  },

  isPermissionsLoaded(state) {
    return state.session.permissionsLoaded
  },
}
