<template>
  <LayoutDefault :main-sidebar="false" :header-session-nav="true">
    <template #main-content>
      <div class="centered-container">
        <div class="max-w-prose mx-auto">
          <fw-heading size="h1">{{ $t('profile') }}</fw-heading>
          <BlockAccountUpdate class="my-5" />
        </div>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import BlockAccountUpdate from '@/fw-modules/fw-core-vue/id/components/blocks/BlockAccountUpdate'

export default {
  components: {
    LayoutDefault,
    BlockAccountUpdate,
  },

  data() {
    return {
      loading: false,
    }
  },
}
</script>

<i18n>
{
  "pt": {
    "profile": "Perfil"
  },
  "en": {
    "profile": "Profile"
  }
}
</i18n>
