<template>
  <fw-layout full mobile-ready back-to="/">
    <template #main-content>
      <fw-panel-page-header
        class="xl:max-w-screen-xl mx-auto"
        title="Repositórios de ficheiros"
        add-lateral-padding
      ></fw-panel-page-header>
      <PanelBucketList
        filter-with-routes
        join-units-and-classes
        :app-name="appName"
        show-view-options
        @go-to-new-bucket="$router.push({ name: 'new-bucket' })"
      />
      <!-- </div>-->
    </template>
  </fw-layout>
</template>

<script>
import PanelBucketList from '../components/panels/PanelBucketList'

export default {
  components: {
    PanelBucketList,
  },

  computed: {
    appName() {
      return this.$store.state.appName
    },
  },
}
</script>
