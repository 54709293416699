<template>
  <div
    class="flex items-center justify-center cursor-pointer update-modal-uploader h-full"
    :class="{
      'absolute left-0 top-0 h-full w-full z-50 bg-white backdrop-filter backdrop-blur-sm bg-opacity-95 flex items-center justify-center':
        layout == 'full-dragzone',
    }"
  >
    <file-upload
      v-if="allowNewUploads || (!allowNewUploads && filesToUpload.length)"
      :ref="referenceId"
      v-model="filesToUpload"
      class="h-full"
      :class="{
        'is-hidden': !allowNewUploads && filesToUpload.length,
        'is-custom': isCustom,
        'is-minimal': layout == 'minimal',
        'is-tiny': layout == 'tiny',
      }"
      chunk-enabled
      :extensions="getExtensions"
      :accept="getAccept"
      :input-id="referenceId"
      :active="true"
      :size="size"
      :drop="true"
      :multiple="true"
      :drop-directory="true"
      :maximum="limit"
      :thread="threads"
      :chunk="{
        action: uploadPath,
        minSize: 0.1,
        headers: { Authorization: $store.getters.getToken },
        maxActive: 1,
        maxRetries: 5,
        startBody: {
          code: fileCode,
          type: fileType,
        },
      }"
      @input-filter="inputFilter"
      @input-file="uploadFile"
    >
      <slot name="navbar">
        <div v-if="layout == 'tiny'">
          <fw-button class="flex items-center gap-1 h-full" :label="label || $t('files')" :class="uploaderClass">
            <fw-icon-cloud-upload class="w-5 h-5" />
          </fw-button>
        </div>
        <div v-else-if="layout == 'simple'">
          <fw-button
            class="flex items-center gap-1 h-full"
            :type="simpleUploaderType"
            :label="label || $t('files')"
            :class="uploaderClass"
          >
            <fw-icon-cloud-upload class="w-5 h-5" /> <span class="hidden md:flex">{{ label || $t('files') }}</span>
          </fw-button>
        </div>
        <div
          v-else-if="layout == 'full-dragzone'"
          class="flex gap-2 items-center flex-col text-gray-500 mx-auto max-w-screen-sm"
          :class="uploaderClass"
        >
          <fw-icon-cloud-upload class="w-10 h-10 animate-pulse"></fw-icon-cloud-upload>
          <span>{{ $t('dropFiles') }}</span>
        </div>
        <div
          v-else
          class="flex gap-1 items-center justify-center flex-col rounded-lg p-3 text-gray-600 h-full"
          :class="[{ 'border border-dashed border-gray-300': !borderless }, uploaderClass]"
        >
          <span class="flex flex-col">
            <span class="flex gap-2 items-center">
              <fw-icon-cloud-upload class="w-5 h-5" />
              <span class="text-sm">{{ label || $t('files') }}</span>
            </span>
            <span class="text-xs opacity-70">{{ $t('dragToArea') }}</span>
          </span>
        </div>
      </slot>
    </file-upload>
    <fw-modal :active.sync="isUploading" size="3xl" @close="closeModal">
      <div>
        <fw-heading marginless>{{ $t('uploadingFiles') }}</fw-heading>
        <div class="opacity-75 text-xs text-center font-medium bg-gray-100 rounded-lg my-2 p-1">
          {{ $t('doNotCloseWindow') }}
        </div>
        <fw-label>{{ filesToUpload.length }} {{ $t('file') }}{{ filesToUpload.length > 1 ? 's' : '' }}</fw-label>
        <div class="mt-2 overflow-auto max-h-96">
          <div class="grid grid-cols-1 gap-3">
            <div v-for="file in filesToUpload" :key="file.id" class="p-2 border-b">
              <div class="flex flex-col gap-0.5">
                <div class="flex gap-1">
                  <div class="flex-shrink-0">
                    <fw-icon-attachment class="w-4 h-4 mt-0.5" />
                  </div>
                  <div class="flex-1 text-sm font-medium">
                    <v-clamp autoresize :max-lines="1">{{ file.name }}</v-clamp>
                  </div>
                  <div class="text-xs text-gray-500 flex-shrink-0 mt-0.5 font-medium">
                    {{ file.size | formatNumber }}
                  </div>
                </div>
                <div>
                  <div class="text-xs text-gray-500">
                    <div v-if="!file.error && !file.active && !file.success" class="my-1 animate-pulse">
                      {{ $t('waiting') }}
                    </div>
                    <b-progress
                      v-if="!file.error"
                      size="is-tiny"
                      type="is-primary"
                      :value="parseInt(file.progress)"
                      class="mb-2"
                    ></b-progress>
                    <div class="flex justify-between">
                      <div v-if="!file.error" :class="{ 'animate-pulse text-primary': file.active && !file.success }">
                        {{ file.progress | formatPercentage }}%
                      </div>
                      <div v-if="file.error" class="font-bold text-red-700">
                        <div v-if="file.error == 'size'">{{ $t('fileBiggerThan') }} {{ size | prettyBytes }}</div>
                        <div v-else></div>
                      </div>
                      <div class="flex items-center gap-2">
                        <div
                          v-if="file.chunk && !(file.error || file.success)"
                          class="controls flex items-center gap-2 text-lg"
                        >
                          <a v-if="file.active" @click="pauseFile(file)">
                            <faicon icon="pause-circle" />
                          </a>
                          <a v-if="!file.active && file.chunk.hasChunksToUpload" @click="resumeFile(file)">
                            <faicon icon="play-circle" />
                          </a>
                          <a class="remove-file" @click="removeFile(file)"> <faicon icon="times-circle"/></a>
                        </div>
                        <div v-if="file.success" class="text-lg">
                          <faicon class="text-primary" icon="check-circle" />
                          <a v-if="!clearAfter" class="remove-file" @click="removeFile(file)">
                            <faicon icon="times-circle" />
                          </a>
                        </div>
                        <div v-if="file.error">
                          <a class="remove-file" @click="removeFile(file)">
                            <faicon icon="times-circle" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="file.active && file.chunk && file.chunk.chunks && file.chunk.chunksUploaded"
                    class="text-xs text-gray-500 hidden"
                  >
                    {{ $t('uploading') }} {{ file.chunk.chunks.length }} / {{ file.chunk.chunksUploading.length }} /
                    {{ file.chunk.chunksUploaded.length }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fw-modal>
  </div>
</template>

<script>
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import FileUpload from 'vue-upload-component'
import VClamp from 'vue-clamp'
import ChunkUploadHandler from '@/fw-modules/fw-core-vue/utilities/ChunkUploadHandler'

export default {
  components: {
    FileUpload,
    VClamp,
  },

  props: {
    uploaderClass: {
      type: String,
      default: 'w-full rounded',
    },
    referenceId: {
      type: String,
      default: 'uploader_update',
    },
    threads: {
      type: Number,
      default: 5,
    },
    label: {
      type: String,
      default: null,
    },
    fileType: {
      type: String,
      default: 'file',
    },
    fileCode: String,
    allowed: {
      type: String,
      default: 'all',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    allowNewUploads: {
      type: Boolean,
      default: true,
    },
    layout: {
      type: String,
      default: 'minimal',
    },
    size: {
      type: Number,
      default: 0,
    },
    limit: Number,
    clearAfter: {
      type: Boolean,
      default: true,
    },
    simpleUploaderType: {
      type: String,
      default: '',
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
    newFileContext: {
      type: Object,
      default: null,
    },
    uploadPath: {
      type: String,
      default: `${FwEnvConfig.apiUrlStorage}/v1/file`,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filesToUpload: [],
      handler: ChunkUploadHandler,
    }
  },

  computed: {
    isUploading() {
      return !!(this.filesToUpload && this.filesToUpload.length)
    },
    getExtensions() {
      const extensionsMap = {
        images: ['gif', 'jpg', 'jpeg', 'png', 'webp'],
        videos: ['mov', 'avi', 'mp4', 'm4v'],
        pdf: ['pdf'],
        any: [],
      }
      return extensionsMap[this.allowed]
    },
    getAccept() {
      const acceptMap = {
        images: 'image/png,image/gif,image/jpeg,image/webp',
        videos: 'video/mp4,video/webm,video/ogg,video/quicktime,video/mp4',
        pdf: 'application/pdf',
        any: '',
      }
      return acceptMap[this.allowed]
    },
  },

  beforeDestroy() {
    this.filesToUpload = []
  },

  methods: {
    closeModal() {
      if (this.isUploading) {
        this.$buefy.dialog.confirm({
          type: 'is-dark',
          message: this.$t('confirmCloseModal'),
          confirmText: this.$t('close'),
          cancelText: this.$t('cancel'),
          onConfirm: async () => {
            for (const fileKey in this.filesToUpload) {
              this.clear(this.filesToUpload[fileKey])
            }
          },
        })
      }
    },

    clear(file) {
      if (this.$refs[this.referenceId]) {
        this.$refs[this.referenceId].remove(file)
      } else {
        for (let idx in this.filesToUpload) {
          if (this.filesToUpload[idx].id == file.id) {
            this.filesToUpload.splice(idx, 1)
            break
          }
        }
      }
    },

    destroyUploadingFile(file) {
      file.chunk.pause()

      if (this.$refs[this.referenceId]) {
        this.$refs[this.referenceId].remove(file)
      } else {
        this.clear(file)
      }
    },

    // Controls

    resumeNextFile(file) {
      const next = this.filesToUpload.find(fileQueued => {
        if (!fileQueued.active && fileQueued.progress < 0.1 && fileQueued.id != file.id) {
          return fileQueued
        }
      })

      // Upload queue
      if (next) {
        next.active = true
        this.$refs[this.referenceId].uploadChunk(next)
      }
    },

    resumeFile(file) {
      setTimeout(() => {
        file.chunk.resume()
      }, 100)
    },

    pauseFile(file) {
      this.resumeNextFile(file)
      setTimeout(() => {
        file.chunk.pause()
      }, 100)
    },

    stopFile(file) {
      this.destroyUploadingFile(file)
      setTimeout(() => {
        this.resumeNextFile(file)
      }, 100)
    },

    removeFile(file) {
      this.destroyUploadingFile(file)
    },

    uploadFile(newFile, oldFile) {
      // Keep this here...
      this.$emit('dragleave')

      if (newFile) {
        if (this.newFileContext && !newFile.context) {
          newFile.context = Object.assign({}, this.newFileContext)
        }
        if (!oldFile) {
          this.$refs[this.referenceId].active = true
        }
      }

      if (newFile && newFile.success) {
        this.$emit('upload', [newFile])

        if (this.clearAfter) {
          setTimeout(() => {
            this.clear(newFile)
          }, 500)
        }
      }
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
  },
}
</script>

<style lang="scss">
.progress-wrapper:not(:last-child) {
  margin-bottom: 0.5rem;
}
</style>

<i18n>
{
  "pt": {
    "files": "Adicionar ficheiros",
    "dropFiles": "Large os ficheiros nesta área para iniciar o upload.",
    "dragToArea": "Ou arraste para esta zona",
    "file": "ficheiro",
    "uploadingFiles": "Ficheiros em carregamento",
    "waiting": "Em espera",
    "fileBiggerThan": "O ficheiro é maior que",
    "uploading": "A carregar",
    "doNotCloseWindow": "Por favor, não feche esta janela até o upload de todos os ficheiros estar concluído.",
    "close": "Fechar",
    "cancel": "Cancelar",
    "confirmCloseModal": "Ao fechar esta janela, o upload de todos os ficheiros irá ser interrompido. Tem a certeza?"
  },
  "en": {
    "files": "Add files",
    "dropFiles": "Drop the files in this area to start uploading.",
    "dragToArea": "Or drag to this area",
    "file": "file",
    "uploadingFiles": "Uploading files",
    "waiting": "Waiting",
    "fileBiggerThan": "The file is larger than",
    "uploading": "Uploading",
    "doNotCloseWindow": "Please, do not close this window while files are uploading.",
    "close": "Close",
    "cancel": "Cancel",
    "confirmCloseModal": "Closing this window will stop all uploading files. Are you sure?"
  }
}
</i18n>
