export default {
  scrollIsAtBottom() {
    const elChat = document.getElementById('chat-messages')
    return elChat && elChat.scrollHeight - elChat.scrollTop - 50 <= elChat.clientHeight
  },
  scrollToBottom(ms, callback) {
    setTimeout(() => {
      const elChat = document.getElementById('chat-messages')
      if (elChat) elChat.scrollTop = elChat.scrollHeight + 20
      if (callback) callback()
    }, ms || 500)
  },
}
