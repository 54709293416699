import ServiceActivity from '@/fw-modules/fw-core-vue/activity/services/ServiceActivity'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import ServiceNotifications from '@/fw-modules/fw-core-vue/notifications/services/ServiceNotifications'

// Keep it! We need to make sure we send logs
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

import router from '@/router'
import axios from 'axios'
import { SnackbarProgrammatic as Snackbar } from 'buefy'
import isEmpty from 'lodash/isEmpty'
import Vue from 'vue'

var serviceMeetings
try {
  serviceMeetings = require('../../fw-meetings-vue/services/ServiceMeetings')
} catch (e) {
  //
}

var serviceChat
try {
  serviceChat = require('../../fw-core-vue/chats/services/ServiceChat')
} catch (e) {
  //
}

function loadData(state) {
  // Make sure we handle non-authenticated routes
  // Just grab notifications (and chat unread messages) if user is logged in
  // (or at least we have a token on local storage)
  if (!state.session.appsLoaded && state.session.token) {
    state.session.appsLoaded = true
    ServiceAuth.getApps().then(apps => {
      state.session.apps = apps
    })
  }

  // Load notifications
  if (!state.session.unreadNotificationsLoaded && state.session.token) {
    state.session.unreadNotificationsLoaded = true
    ServiceNotifications.allUnread().then(notifications => {
      let unread = 0
      for (let notification of notifications) {
        if (notification.unread) unread += 1
      }
      state.session.unreadNotifications = notifications
      state.session.unreadNotificationsCount = unread
    })
  }

  // Load chat messages
  if (!state.session.unreadChatMessagesLoaded && serviceChat && state.session.token) {
    state.session.unreadChatMessagesLoaded = true
    serviceChat.default.allUnread().then(messages => {
      let unread = 0
      for (let message of Object.values(messages)) {
        unread += message.unread
      }
      state.session.unreadChatMessages = messages
      state.session.unreadChatMessagesCount = unread
    })
  }
}

export default {
  saveActivityAction({ dispatch }, log) {
    // LOG STRUCTURE
    //service, elementType, elementID, action, userInteraction = false
    let metadata = {
      service: log.service,
      frontend: true,
      userInteraction: log.userInteraction ?? false,
    }
    let extendedmetadata = log.metadata ?? {}
    let refKeys = log.reference_keys ?? {}
    if (log.service == 'exams' && log.elementType == 'instance' && log.elementID !== 'NULL') {
      refKeys['INSTANCE_KEY'] = log.elementID
    }
    metadata = { ...metadata, ...extendedmetadata }
    let logData = {
      context: log.elementType,
      context_key: log.elementID,
      message: {
        code: log.action,
      },
      metadata: metadata,
      reference_keys: refKeys,
      level_name: 'info',
    }
    console.log('Log activity: ', logData)
    dispatch('saveActivity', logData)
  },

  saveActivity({ commit, state, dispatch }, log) {
    if (state.activityTimer == null) {
      commit(
        'setActivityTimer',
        setTimeout(function() {
          dispatch('uploadActivitiesLogs')
          //Reset timer
          commit('setActivityTimer', null)
        }, 5000)
      )
    }
    commit('addActivityLog', log)
  },
  async uploadActivitiesLogs({ commit, state }) {
    if (state.session && state.session.activityLogs && state.session.activityLogs.length > 0) {
      try {
        await ServiceActivity.saveLogs(state.session.activityLogs)
        //Reset logs
        commit('resetActivityLogs')
      } catch (e) {
        console.error(e)
      }
    }
  },
  startTimer({ commit, state }, start = true) {
    if (start) {
      if (state.clockTimer === null) {
        commit(
          'setClockTimer',
          setInterval(function() {
            commit('timerTick')
          }, 1000)
        )
      }
    } else {
      //stop timer and remove it
      if (state.clockTimer !== null) {
        clearInterval(state.clockTimer)
        commit('setClockTimer', null)
      }
    }
  },
  setTimerRef({ commit }, timestampMs) {
    commit('setNow', timestampMs)
  },

  setLanguage({ commit, dispatch, state }, language) {
    // Save language if user is authenticated
    if (state.session.token) {
      ServiceAuth.updateSelfUser({ 'option.language': language || 'pt' })
        .then(response => {
          if (response) {
            dispatch('setUser', { user: response })
          }
        })
        .catch(err => {
          console.error(err)
        })
    }

    localStorage.setItem('settings.language', language)
    commit('setLanguage', language)
  },

  // Logout
  resetToken({ commit }) {
    localStorage.removeItem('session.user')
    localStorage.removeItem('session.token')
    localStorage.removeItem('session.isPlugin')
    delete axios.defaults.headers.common['Authorization']

    commit('resetToken')
  },
  logout({ commit }) {
    function clearData() {
      localStorage.removeItem('session.user')
      localStorage.removeItem('session.token')
      localStorage.removeItem('session.isPlugin')
      delete axios.defaults.headers.common['Authorization']
      commit('logout')
    }

    async function logoutCore() {
      if (FwEnvConfig.appsUCIdSSO.includes(process.env.VUE_APP_KEY) && FwEnvConfig.appUrlUCId) {
        clearData()
        window.location.href = `${FwEnvConfig.appUrlUCId}/${process.env.VUE_APP_KEY}`
        return
      }

      let goToRoute = { name: 'login' }
      if (process.env.VUE_APP_KEY == 'ucmeetingscreen') {
        goToRoute = null
      } else if (process.env.VUE_APP_KEY == 'ucid') {
        goToRoute = { name: 'home' }
      }

      const user = JSON.parse(localStorage.getItem('session.user'))
      try {
        if (user && user.isAnonymous) {
          if (process.env.VUE_APP_KEY == 'ucmeetings' && router.currentRoute) {
            if (router.currentRoute.name == 'live') {
              goToRoute = { name: 'anonymousJoin', params: router.currentRoute.params }
            } else if (router.currentRoute.name == 'liveWithCode') {
              goToRoute = { name: 'anonymousJoinWithCode', params: router.currentRoute.params }
            }
          }

          if (serviceMeetings) await serviceMeetings.default.logoutAnonymous()
        } else {
          await ServiceAuth.logout()
        }
      } catch (error) {
        console.error('Failed to logout', error)
      }

      clearData()

      // reload websockets
      Vue.prototype.$socket.close()

      if (goToRoute) {
        router.push(goToRoute)
      }
    }

    // ServiceStorage.sendLogsToBackend(true)
    //   .then(logoutCore)
    //   .catch(logoutCore)
    logoutCore()
  },

  logoutWithoutRedirect({ commit }) {
    function clearData() {
      localStorage.removeItem('session.user')
      localStorage.removeItem('session.token')
      localStorage.removeItem('session.isPlugin')
      delete axios.defaults.headers.common['Authorization']
      commit('logout')
    }

    async function logoutCore() {
      if (FwEnvConfig.appsUCIdSSO.includes(process.env.VUE_APP_KEY) && FwEnvConfig.appUrlUCId) {
        clearData()
        //window.location.href = `${FwEnvConfig.appUrlUCId}/${process.env.VUE_APP_KEY}`
        return
      }

      /*let goToRoute = { name: 'login' }
      if (process.env.VUE_APP_KEY == 'ucmeetingscreen') {
        goToRoute = null
      } else if (process.env.VUE_APP_KEY == 'ucid') {
        goToRoute = { name: 'home' }
      }*/

      const user = JSON.parse(localStorage.getItem('session.user'))
      try {
        if (user && user.isAnonymous) {
          if (process.env.VUE_APP_KEY == 'ucmeetings' && router.currentRoute) {
            /*if (router.currentRoute.name == 'live') {
              goToRoute = { name: 'anonymousJoin', params: router.currentRoute.params }
            } else if (router.currentRoute.name == 'liveWithCode') {
              goToRoute = { name: 'anonymousJoinWithCode', params: router.currentRoute.params }
            }*/
          }
          if (serviceMeetings) await serviceMeetings.default.logoutAnonymous()
        } else {
          await ServiceAuth.logout()
        }
      } catch (error) {
        console.error('Failed to logout', error)
      }

      clearData()

      // reload websockets
      //Vue.prototype.$socket.close()
    }

    // ServiceStorage.sendLogsToBackend(true)
    //   .then(logoutCore)
    //   .catch(logoutCore)
    logoutCore()
  },

  // Login
  login({ commit }, { email, password, keepSignIn }) {
    return new Promise((resolve, reject) => {
      ServiceAuth.login(email, password, keepSignIn)
        .then(response => {
          if (response) {
            commit('setNewLogin', response.data)
            resolve(response)
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  setNewLogin({ commit }, { user, token }) {
    commit('setNewLogin', { user, token })
  },

  setUser({ commit }, data) {
    // Valida se existe algum token registado no local storage
    const token = localStorage.getItem('session.token')
    const isPlugin = localStorage.getItem('session.isPlugin')
    data = data || {}
    if (data.reload === undefined) data.reload = true

    // Get data from logged user and his teacher/student profile
    if (token && !isPlugin && (isEmpty(this.state.session.user) || data.reload)) {
      if (isEmpty(data.user)) {
        return ServiceAuth.getSelfUser().then(user => {
          user.photoUrl = ServiceStorage.getUserImageViewUrl(user)

          localStorage.setItem('session.user', JSON.stringify(user))
          commit('setUser', user)

          //  && process.env.VUE_APP_KEY == 'ucteacher'
          if (!isPlugin) loadData(this.state)

          return user
        })
      } else {
        localStorage.setItem('session.user', JSON.stringify(data.user))
        commit('setUser', data.user)
      }
    }

    //  && process.env.VUE_APP_KEY == 'ucteacher'
    if (!isPlugin) loadData(this.state)
  },

  updateUserMeetingAlias({ commit }, alias) {
    const userJSON = localStorage.getItem('session.user')
    if (userJSON) {
      const user = JSON.parse(userJSON)
      user.meeting.key_alias = alias
      user.meeting.alias = [alias]
      localStorage.setItem('session.user', JSON.stringify(user))
      commit('setUser', user)
    }
  },

  removeUserPhoto() {
    localStorage.removeItem('session.userPhoto')
  },

  removeUnreadNotification({ commit }, key) {
    commit('removeUnreadNotification', key)
  },

  setActiveChat({ commit }, channelKey) {
    commit('setActiveChat', channelKey)
  },

  removeActiveChat({ commit }, channelKey) {
    commit('removeActiveChat', channelKey)
  },

  setContext({ commit }, context = null) {
    commit('setContext', context)
  },

  setApiStatus({ commit }, isActive) {
    // Valida de se o estado da ligação é o mesmo que registado no store
    if (this.state.api.isActive != isActive) {
      commit('setApiStatus', isActive)

      // Confirma que o utilizador ainda não foi notificado
      if (!isActive) {
        Snackbar.open({
          message: 'Ocorreu um erro na ligação ao servidor.',
          type: 'is-warning',
          position: 'is-top-right',
          actionText: 'Recarregar',
          indefinite: true,
          onAction: () => {
            router.go()
          },
        })
      }
    }
  },
  setApiIgnore401({ commit }, ignore401) {
    if (this.state.api.ignore401 != ignore401) {
      commit('setApiIgnore401', ignore401)
    }
  },

  setConnectedUsers({ commit }, { page, users }) {
    commit('setConnectedUsers', { page, users })
  },
}
