<template>
  <div
    class="group bg-gradient-to-r from-teal-200 via-teal-100 to-teal-400 hover:to-teal-300 flex flex-col items-center justify-center text-gray-500 text-sm p-10 rounded-xl"
  >
    <div>Ainda não tem grupos.</div>
    <fw-icon-group class="w-20 h-20 my-5 opacity-90"></fw-icon-group>
    <div class="flex items-center justify-between gap-2 opacity-90">
      <div class="flex items-center">
        <fw-icon-live class="w-10 h-10"></fw-icon-live>
      </div>
      <div class="flex items-center">
        <fw-icon-chats class="w-10 h-10"></fw-icon-chats>
      </div>
      <div class="flex items-center">
        <fw-icon-box-open class="w-10 h-10"></fw-icon-box-open>
      </div>
    </div>
    <div class="mx-auto max-w-prose font-medium text-center my-5 text-lg">
      Crie um novo grupo para ter acesso a uma sala virtual, um canal de conversação e uma área de repositórios de
      ficheiros partilhados, disponível a todos os membros que entender convidar.
    </div>
    <fw-button class="group-hover:scale-110 text-primary" @click.native="addNewGroupModal">Criar novo</fw-button>
  </div>
</template>

<script>
import ServiceChat from '@/fw-modules/fw-core-vue/chats/services/ServiceChat'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  props: {
    redirectAfterCreated: Boolean,
  },

  data() {
    return {
      newGroupTitle: null,
      chatLoadDone: false,
    }
  },

  methods: {
    async addNewGroup() {
      if (!this.newGroupTitle.length || this.groupLoading) return
      this.groupLoading = true

      try {
        const meeting = await ServiceMeetings.create(this.newGroupTitle)
        const chat = await ServiceChat.getChannel(meeting.chat.key)

        if (this.redirectAfterCreated) {
          this.$router.push({ name: 'groups' })
        } else {
          this.$emit('set-chat', chat, true, false, false, this.chatLoadDone, true)
        }

        this.newGroupTitle = ''
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        let message = `Não foi possível adicionar o email. Por favor, tente mais tarde.
            Caso o problema persista, contacte-nos através do nosso canal de suporte.`
        if (!errorKey) {
          message = 'Ocorreu um erro, por favor tente novamente mais tarde'
          console.error('Failed to add chat by email', error)
        }

        this.$buefy.snackbar.open({
          duration: 5000,
          message: message,
          type: 'is-danger',
          position: 'is-top-right',
          queue: false,
        })
      } finally {
        this.groupLoading = false
      }
    },
    addNewGroupModal() {
      this.$buefy.dialog.prompt({
        title: 'Novo grupo',
        message: `<div>Indique o nome do novo grupo que pretende criar.</div>
        <div class="text-sm">Mais tarde poderá alterar esta configuração nas preferências do grupo.</div>`,
        inputAttrs: {
          type: 'text',
          placeholder: 'e.g. O meu grupo',
          maxlength: 150,
          min: 2,
        },
        confirmText: 'Adicionar',
        cancelText: 'Cancelar',
        trapFocus: true,
        onConfirm: value => {
          this.newGroupTitle = value
          this.addNewGroup()
        },
      })
    },
  },
}
</script>
