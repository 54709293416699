<template>
  <div v-if="userLoggedIn" class="items-center gap-2 h-full min-w-max flex">
    <b-dropdown aria-role="list" position="is-bottom-left">
      <fw-button-dropdown slot="trigger" aria-role="listitem" type="transparent" label="Ações rápidas" :chevron="false">
        <fw-icon-add class="w-5 h-5" />
      </fw-button-dropdown>
      <b-dropdown-item
        v-if="appQuickActions.includes('create-support-tasks')"
        class="md:w-56 z-20"
        has-link
        aria-role="menuitem"
      >
        <fw-button type="dropdown-item" icon="question" @click.native="openCreateTaskModal"
          >Criar pedido de suporte</fw-button
        >
      </b-dropdown-item>
    </b-dropdown>
    <WrapperCreateSupportTask :show="isCreateTaskModalActive" @close="isCreateTaskModalActive = false" />
  </div>
</template>

<script>
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import WrapperCreateSupportTask from '@/fw-modules/fw-core-vue/tasks/components/wrappers/WrapperCreateSupportTask'

export default {
  components: {
    WrapperCreateSupportTask,
  },

  data() {
    return {
      isCreateTaskModalActive: false,
    }
  },

  computed: {
    userLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    user() {
      return this.$store.getters.getUser
    },
    appQuickActions() {
      return FwEnvConfig?.appMeta?.quickActions || []
    },
  },

  methods: {
    openCreateTaskModal() {
      this.isCreateTaskModalActive = true
    },
  },
}
</script>
