var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('figure',{staticClass:"relative",class:[
      {
        'scale-50 opacity-50': !_vm.loaded && _vm.animated,
        'border border-primary': !_vm.borderless && _vm.itsYou,
      },
      _vm.style(),
    ]},[(_vm.user && _vm.getPhotoUrl)?_c('img',{staticClass:"brightness-105 contrast-125",attrs:{"src":_vm.getPhotoUrl}}):(_vm.user)?_c('span',[_vm._v(_vm._s(_vm.user.initials))]):(_vm.brand)?_c('span',[_vm._v(_vm._s(_vm.brand))]):(_vm.group)?_c('fw-icon-group',{staticClass:"w-full h-full p-1",class:{ 'md:p-2': _vm.size == 'xl' }}):(_vm.icon)?_c('fw-icon-' + _vm.icon,{tag:"component",staticClass:"w-full h-full p-2",class:{ 'md:p-4': _vm.size == 'xl' }}):_c('fw-icon-user2',{staticClass:"w-full h-full p-2",class:{ 'md:p-4': _vm.size == 'xl' }})],1),(_vm.showAsOnline)?_c('fw-dot',{staticClass:"-top-0.5 -right-0.5",attrs:{"ping":false}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }