<template>
  <LayoutDefault full :main-sidebar="false" :tapbar="false" back-to="-1">
    <template #main>
      <!-- <RecycleScroller
        v-if="state === 'loaded'"
        v-slot="{ pagenumber }"
        :items="pagesArray"
        :min-item-size="54"
        :buffer="5"
        key-field="key"
      >
        <pdf :key="pagenumber" :page="pagenumber" :src="loadingTask"></pdf>
      </RecycleScroller>-->
      <div v-if="state === 'loaded'" class="w-full relative">
        <div
          class="flex bg-gray-100 border-b border-gray-200 items-center p-3 pl-5 text-gray-800 absolute left-0 right-0 z-10"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="fill-current h-5 w-5"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 16H8V8h4a4 4 0 1 1 0 8zm-2-6v4h2a2 2 0 1 0 0-4h-2zm5-6H5v16h14V8h-4V4zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992z"
            />
          </svg>
          <span
            v-if="$route.params.filename && $route.params.filename !== '-1'"
            class="font-semibold ml-2 text-gray-600"
            >{{ $route.params.filename }}</span
          >
        </div>
        <div class="p-10 pt-16 w-full h-full overflow-y-auto">
          <pdf
            v-for="i in pageCount"
            :key="i"
            class="w-full mb-10 mx-auto shadow max-w-5xl"
            :src="loadingTask"
            :page="i"
            @error="pdfError"
          ></pdf>
        </div>
      </div>
      <div v-else-if="state === 'error'" class="flex h-full w-full justify-center items-center">
        <div class="text-gray-500 text-center">
          <svg
            class="fill-current h-16 w-16 mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M15 4H5v16h14V8h-4V4zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992zM11 15h2v2h-2v-2zm0-8h2v6h-2V7z"
            />
          </svg>
          <div>
            Ocorreu um erro
          </div>
        </div>
      </div>
      <div v-else class="flex h-full w-full justify-center items-center">
        <div class="text-gray-500 text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="fill-current h-16 w-16 mx-auto"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 16H8V8h4a4 4 0 1 1 0 8zm-2-6v4h2a2 2 0 1 0 0-4h-2zm5-6H5v16h14V8h-4V4zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992z"
            />
          </svg>
          <div class="h-3 bg-gray-200 rounded-full overflow-hidden m-2">
            <div class="h-3 bg-gray-500" :style="{ width: progress !== null ? progress : 0 + '%' }"></div>
          </div>
          <div>
            A carregar...
          </div>
        </div>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import pdf from 'vue-pdf'
import ServiceStorage from '../../storage/services/ServiceStorage'
//import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  components: {
    LayoutDefault,
    //RecycleScroller,
    pdf,
  },
  data() {
    return {
      currentPage: 0,
      pageCount: 0,
      pdfUrl: '',
      state: 'loading',
      loadingTask: null,
      progress: null,
    }
  },
  computed: {
    pagesArray() {
      return Array.from({ length: this.pageCount }, (item, index) => index)
    },
  },
  created() {
    this.loadPdf()
  },
  methods: {
    pdfError(msg) {
      console.log('PDFerror')
      console.log(msg)
    },
    loadPdf: async function() {
      let key = this.$route.params.key
      let token = this.$route.params.token
      let filename = this.$route.params.filename
      let url_format = window.localStorage.getItem('file_url_format')
      if (key && token && filename && url_format) {
        this.pdfUrl = ''
        let fileurl = await ServiceStorage.getFileUrl({
          url_format: url_format,
          token: token,
          key: key,
          filename: filename,
        })
        this.pdfUrl = fileurl
        this.loadingTask = pdf.createLoadingTask(fileurl, {
          onProgress: progress => {
            console.log(progress)
            if (progress.total > 0) {
              this.progress = (progress.loaded / progress.total) * 100
            } else {
              this.progress = null
            }
          },
        })
        this.loadingTask.promise
          .then(pdf => {
            this.state = 'loaded'
            this.pageCount = pdf.numPages
          })
          .catch(error => {
            console.log('PDF viewer error')
            console.log(error)
          })
        //this.state = 'loaded'
        console.log(fileurl)
      } else {
        this.state = 'error'
        console.error('File id not defined')
      }
    },
  },
}
</script>
