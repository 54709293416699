<template>
  <div class="opacity-70 hover:opacity-100">
    <fw-button
      v-if="selectedLanguage === 'pt'"
      size="sm"
      type="xlight"
      expanded
      @click.native="selectedLanguage = 'en'"
    >
      <fw-icon-globe class="w-5 h-5 mr-2 opacity-80"></fw-icon-globe>
      {{ languageDescription['pt'] }}
    </fw-button>
    <fw-button v-else size="sm" type="xlight" expanded @click.native="selectedLanguage = 'pt'">
      <fw-icon-globe class="w-5 h-5 mr-2 opacity-80"></fw-icon-globe>
      {{ languageDescription['en'] }}
    </fw-button>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: 'SideBlockLanguage',
  data() {
    return {
      selectedLanguage: store.state.language || this.$i18n.locale || 'pt',
      languageDescription: {
        pt: 'Português',
        en: 'English',
      },
    }
  },
  watch: {
    selectedLanguage() {
      store.dispatch('setLanguage', this.selectedLanguage)
      this.$i18n.locale = this.selectedLanguage
    },
  },
}
</script>
