var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw-panel-box flex flex-col relative py-2 gap-2 rounded-xl",class:[
    {
      'bg-white': _vm.type === 'white',
      'bg-gray-100': _vm.type === 'light',
      'bg-yellow-600 bg-opacity-10': _vm.type === 'orange',
    },
  ]},[(_vm.title || _vm.$slots.toolbar)?_c('div',{staticClass:"flex gap-5 items-center flex-shrink-0 px-4 min-h-9"},[_c('div',{staticClass:"flex-1"},[(_vm.title)?_c('div',{staticClass:"flex items-center gap-3"},[(_vm.icon)?_c('fw-icon-' + _vm.icon,{tag:"component",staticClass:"w-5 h-5 flex-shrink-0"}):_vm._e(),_c('fw-heading',{staticClass:"relative",attrs:{"size":"md"}},[_vm._v(" "+_vm._s(_vm.title || 'Bloco')+" "),(_vm.counter)?_c('fw-badge',{staticClass:"text-gray-500",attrs:{"inline":"","type":"simple"}},[_vm._v(" "+_vm._s(_vm.counter)+_vm._s(_vm.counterTotal ? ' / ' + _vm.counterTotal : '')+" ")]):_vm._e()],1),(_vm.loading)?_c('div',[_c('fw-icon-loading',{staticClass:"w-5 h-5"})],1):_vm._e(),(_vm.checkboxActive)?_c('div',[_c('fw-icon-checkbox-circle',{staticClass:"w-5 h-5 text-primary"})],1):_vm._e()],1):_vm._e()]),(_vm.$slots.toolbar)?_c('div',[_vm._t("toolbar")],2):_vm._e()]):_vm._e(),(_vm.$slots.default)?_c('div',{staticClass:"rounded-xl overflow-auto px-4",class:[_vm.height ? 'height-' + _vm.height : '', _vm.innerClass]},[_vm._t("default")],2):_vm._e(),(_vm.$slots.footer)?_c('div',{staticClass:"mt-2 px-4"},[_vm._t("footer")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }