<template>
  <div class="w-full flex flex-col h-full">
    <!-- Header -->
    <div
      v-if="showHeader"
      class="flex sm:flex-row flex-col gap-3"
      :class="{
        'px-5 py-3 md:pt-4': !paddingless,
      }"
    >
      <div class="flex flex-col gap-1 sm:flex-1">
        <fw-heading v-if="app" size="lg">{{ appLabel(app) }}</fw-heading>
        <fw-heading
          :size="app ? 'md' : 'lg'"
          :muted="!!app"
          :counter="loading ? 0 : notifications.length"
          :counter-total="loading ? 0 : totalResults"
        >
          {{
            filterType == 'inbox' || filterType == 'unread'
              ? 'Inbox'
              : filterType == 'archived'
              ? 'Arquivadas'
              : $t('notifications')
          }}
        </fw-heading>
      </div>
      <div class="flex gap-1 items-center">
        <div v-if="false">
          <fw-tag v-if="stats.unread" type="primary" size="xs"> {{ stats.unread }} por ler </fw-tag>
        </div>
        <div>
          <fw-button
            v-if="filterType == 'inbox' || filterType == 'unread'"
            :type="filterType == 'unread' ? 'link' : 'link-muted'"
            @click.native="$emit('change-type', 'unread')"
          >
            <fw-icon-filter class="w-4 h-4 flex-shrink-0 mr-1 inline-flex" /> {{ $t('unread') }}
          </fw-button>
        </div>
        <div v-if="filterType == 'inbox' || filterType == 'unread'">
          <fw-menu-more>
            <fw-button :type="'dropdown-item'" @click.native="markAll('read')">
              {{ $t('markAsRead') }}
            </fw-button>
          </fw-menu-more>
        </div>
      </div>
    </div>
    <div
      v-if="showHeader"
      :class="{
        'px-5 pb-3 md:px-4': !paddingless,
      }"
    >
      <ContextualSearch
        type="minimal"
        :filter-options="filterOptions"
        :loading="loading"
        :applied-filters="appliedFilters"
        :show-filters="false"
        @search="search"
      />
    </div>

    <!-- Main -->
    <!-- <div v-if="false" class="flex-1 flex flex-col p-5">
      <fw-panel v-if="filterType == 'configuration'" :title="$t('settings')">
        <div v-for="(configuration, index) in configurations" :key="configuration.key || configuration.title">
          <b>{{ configuration.title }}</b>
          <div v-if="configuration.key">
            <span v-if="configuration.active" @click="updateConfiguration(configuration.key, false)">{{
              $t('active')
            }}</span>
            <span v-else @click="updateConfiguration(configuration.key, true)">{{ $t('inactive') }}</span>
          </div>
          <div v-else>
            <span v-if="configuration.active == null" @click="updateConfigurationGroup(index, false)">{{
              $t('partial')
            }}</span>
            <span v-else-if="configuration.active" @click="updateConfigurationGroup(index, false)">{{
              $t('active')
            }}</span>
            <span v-else @click="updateConfigurationGroup(index, true)">{{ $t('inactive') }}</span>
          </div>

          <div v-if="configuration.children">
            <div v-for="child in configuration.children" :key="child.key" style="padding-left: 25px">
              <b>{{ child.title }}</b>
              <span v-if="child.active" @click="updateConfiguration(child.key, false)">{{ $t('active') }}</span>
              <span v-else @click="updateConfiguration(child.key, true)">{{ $t('inactive') }}</span>
              <br />
            </div>
          </div>
          <br />
        </div>
      </fw-panel>
      <fw-panel v-else :title="filterType == 'inbox' ? $t('inbox') : $t('archive')">
        <BlockListNotifications
          :key="filterType"
          type="full"
          :notifications="notifications"
          :view="filterType"
          @remove-from-list="removeNotificationFromList"
        />
      </fw-panel>
    </div> -->
    <div
      class="flex-1 flex flex-col h-full overflow-y-auto"
      :class="{
        'px-5': !paddingless,
        'pt-3': showHeader,
      }"
    >
      <div
        v-if="loading || (notifications.length == 0 && query)"
        class="text-sm text-gray-500 py-10 text-center flex justify-center items-center h-full"
      >
        <div v-if="loading" class="animate-pulse">A carregar...</div>
        <div v-else>
          Sem resultados para o termo <b>"{{ query }}"</b>
        </div>
      </div>
      <BlockListNotifications
        v-else
        :key="filterType"
        :type="type"
        :notifications="notifications"
        :view="filterType"
        :query="query"
        :selected="activeNotificationKey"
        :hide-open="hideOpen"
        :force-open-details="forceOpenDetails"
        @remove-from-list="removeNotificationFromList"
        @update="updateNotification"
      />
    </div>
    <BlockPagination
      v-if="usePagination && totalPages > 1 && !loading"
      :per-page="limit"
      :total="totalResults"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="pageChanged($event)"
    />
    <div v-else-if="!usePagination && totalPages > 1 && !loading" class="text-center">
      <fw-button type="link" @click.native="seeAll()">Ver todas as notificações</fw-button>
    </div>
  </div>
</template>

<script>
import BlockListNotifications from '@/fw-modules/fw-core-vue/notifications/components/blocks/BlockListNotifications'
import ServiceNotifications from '@/fw-modules/fw-core-vue/notifications/services/ServiceNotifications'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch.vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import Vue from 'vue'
export default {
  components: {
    BlockListNotifications,
    ContextualSearch,
    BlockPagination,
  },

  props: {
    type: {
      type: String,
      default: 'full',
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 30,
    },
    app: {
      //to apply a filter based on the context
      type: String,
      default: null,
    },
    isManagement: {
      type: Boolean,
      default: false,
    },
    usePagination: {
      type: Boolean,
      default: false,
    },
    filterType: {
      //by default show all notifications types
      type: String,
      default: null,
    },
    activeNotificationKey: {
      type: String,
      default: null,
    },
    hideOpen: {
      type: Boolean,
      default: false,
    },
    forceOpenDetails: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      //filterType: 'inbox',
      notifications: [],
      subscription: null,
      configurations: [],
      filterOptions: [
        {
          key: 'type',
          label: 'Vista',
          options: [
            {
              key: 'inbox',
              label: 'Inbox',
            },
            {
              key: 'unread',
              label: 'Por ler',
            },
            {
              key: 'archived',
              label: 'Arquivadas',
            },
          ],
        },
      ],
      appliedFilters: [],
      selectedApp: null,
      totalPages: 1,
      totalResults: 0,
      currentResults: 0,
      page: 1,
      query: null,
      stats: null,
    }
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    },
    wsnotifications() {
      return this.$store.getters.getUnreadNotifications
    },
  },

  watch: {
    app(value) {
      //remove all filters related to the application
      this.appliedFilters = this.appliedFilters.filter(filter => {
        return !filter.startsWith('application:')
      })
      this.appliedFilters.push('application:' + value)
      this.loadNotifications()
    },
    filterType(value) {
      this.appliedFilters = this.appliedFilters.filter(filter => {
        return !filter.startsWith('type:')
      })
      this.appliedFilters.push('type:' + value)
      this.loadNotifications()
    },
    wsnotifications() {
      if (this.filterType == 'inbox') {
        this.loadNotifications()
      }
    },
  },

  created() {
    if (this.app) {
      this.appliedFilters.push('application:' + this.app)
    }
    if (this.filterType) {
      this.appliedFilters.push('type:' + this.filterType)
    }
    let appLabel = utils.appLabels()[process.env.VUE_APP_KEY] || 'Aplicação atual'
    this.filterOptions.unshift({
      key: 'application',
      label: 'Aplicação',
      options: [
        {
          key: 'reset',
          label: 'Todas',
        },
        {
          key: process.env.VUE_APP_KEY,
          label: appLabel,
        },
      ],
    })
    this.loadNotifications()
  },

  beforeDestroy() {
    if (this.subscription) {
      this.subscription.destroy()
    }
  },

  methods: {
    async markAll(action) {
      let keys = this.notifications.map(obj => {
        return obj.key
      })
      try {
        await ServiceNotifications.updateBulk(keys, action)
        this.$buefy.snackbar.open({
          message: 'Notificações marcadas como ' + (action == 'read' ? 'lidas' : 'arquivadas') + '.',
          type: 'is-success',
        })
      } catch (e) {
        console.error(e)
      }
    },
    appLabel(appName) {
      return utils.appLabels()[appName] || appName
    },
    addNotificationToList(notification) {
      //if notification is not in the list, add it
      if (!this.notifications.find(n => n.key == notification.key)) {
        this.notifications.unshift(notification)
      }
    },
    updateNotification(notification) {
      console.log('updateNotification', notification)
      for (let idx in this.notifications) {
        if (this.notifications[idx].key == notification.key) {
          //this.notifications[idx] = notification

          // Avoid replacing message with the long one (with html)
          // Keep the short one sent by the backend
          notification.full_message = notification.message
          notification.message = this.notifications[idx].message

          Vue.set(this.notifications, idx, notification)
          break
        }
      }
    },
    seeAll() {
      this.$router.push({
        name: 'notifications',
      })
    },
    pageChanged(page) {
      this.page = page
      this.loadNotifications()
    },
    async search(searchCriteria) {
      /*
       term: this.searchTerm,
        filters: this.selectedFilters,
        orderBy: this.orderItemsBy != null ? this.orderItemsBy.key : null,
        orderDirection: this.orderItemsBy != null ? this.orderDirection : null,
        user: this.user,
        dates: this.dates,
      */

      console.log('Searching...')
      console.log(searchCriteria)

      this.appliedFilters = typeof searchCriteria != 'undefined' ? searchCriteria.filters : []
      this.query = searchCriteria.term
      this.loadNotifications()
    },
    /*async changeFilter(type) {
      if (!this.loading && type != this.filterType) {
        this.filterType = type

        if (this.filterType === 'configuration') {
          await this.loadConfigurations()
        } else {
          await this.loadNotifications()
        }
      }
    },*/

    removeNotificationFromList(key) {
      this.$emit('remove-from-list', key)
      for (let idx in this.notifications) {
        if (this.notifications[idx].key == key) {
          this.notifications.splice(idx, 1)
          break
        }
      }
    },

    unload() {
      if (this.subscription) {
        this.subscription.destroy()
      }
    },
    async loadNotifications() {
      if (this.loading) return
      this.loading = true

      this.unload()

      try {
        let filterType = null
        let app = null
        for (let index = 0; index < this.appliedFilters.length; index++) {
          const filterParts = this.appliedFilters[index].split(':')
          if (filterParts[0] == 'type' && filterParts[1] != 'reset') {
            filterType = filterParts[1]
          } else if (filterParts[0] == 'application' && filterParts[1] != 'reset') {
            app = filterParts[1]
          }
        }
        const response = await ServiceNotifications.all(filterType, app, this.query, this.tags, this.limit, this.page)
        this.notifications = response.notifications
        this.totalPages = response.pagination.total_pages
        this.totalResults = response.pagination.total_items
        this.currentResults = response.pagination.current_items
        this.stats = response.stats
        //Communication with the parent to update the context
        if (this.$parent && this.$parent.$parent && this.$parent.$parent.setContext) {
          this.$parent.$parent.setContext(response.filters.application, response.stats)
        }
        this.$emit('unread-count', this.notifications.length)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    /*async loadConfigurations() {
      if (this.loading) return
      this.loading = true

      this.unload()

      try {
        this.configurations = await ServiceNotifications.configurations()
      } finally {
        this.loading = false
      }
    },*/

    /*getActiveConfigurations() {
      const keys = new Set()
      for (let configuration of this.configurations) {
        if (configuration.key) {
          if (configuration.active) {
            keys.add(configuration.key)
          }
        } else {
          for (let child of configuration.children) {
            if (child.active) {
              keys.add(child.key)
            }
          }
        }
      }
      return keys
    },*/
    /*async updateConfiguration(key, active) {
      const keys = this.getActiveConfigurations()
      if (active) keys.add(key)
      else keys.delete(key)

      this.configurations = await ServiceNotifications.updateConfigurations([...keys])
    },
    async updateConfigurationGroup(idx, active) {
      const keys = this.getActiveConfigurations()
      for (let child of this.configurations[idx].children) {
        if (active) keys.add(child.key)
        else keys.delete(child.key)
      }

      this.configurations = await ServiceNotifications.updateConfigurations([...keys])
    },*/
  },
}
</script>

<i18n>
{
  "pt": {
    "notifications": "Notificações",
    "inbox": "Caixa de entrada",
    "archive":"Arquivo",
    "settings":"Configurações",
    "active": "Activo",
    "inactive": "Inactivo",
    "partial": "Parcial",
    "unread": "Por ler",
    "archiveAll": "Arquivar todas",
    "markAsRead": "Marcar todas como lidas"
  },
  "en": {
    "notifications": "Notifications",
    "inbox": "Inbox",
    "archive":"Archive",
    "settings":"Settings",
    "active": "Active",
    "inactive": "Inactive",
    "partial": "Partial",
    "unread": "Unread",
    "archiveAll": "Archive all",
    "markAsRead": "Mark all as read"
  }
}
</i18n>
