<template>
  <div
    :class="[
      {
        'text-gray-500': muted,
      },
      style,
    ]"
  >
    <slot />
    <fw-badge v-if="counter" inline type="simple" class="text-gray-500 ml-1"
      >{{ counter }}{{ counterTotal ? ' / ' + counterTotal : '' }}</fw-badge
    >
  </div>
</template>

<script>
export default {
  name: 'FwHeading',
  props: {
    size: {
      type: String,
      default: 'lg',
    },
    muted: {
      type: Boolean,
    },
    counter: {
      type: [String, Number],
    },
    counterTotal: {
      type: [String, Number],
    },
  },

  computed: {
    style() {
      const sizesMap = {
        h1: 'font-bold text-3xl',
        h2: 'font-bold text-2xl',
        h3: 'font-semibold text-xl',
        h4: 'font-semibold text-md leading-5',
        '5xl': 'font-bold text-5xl leading-[2.75rem]',
        '4xl': 'font-bold text-4xl leading-[2.75rem]',
        '3xl': 'font-bold text-3xl leading-[2.25rem]',
        '2xl': 'font-bold text-2xl',
        xl: 'font-bold text-xl',
        lg: 'font-bold text-lg',
        md: 'font-bold text-md leading-5',
        sm: 'font-bold text-sm leading-5',
        label: 'font-bold text-sm text-gray-500',
      }
      return sizesMap[this.size]
    },
  },
}
</script>
