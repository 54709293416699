<template>
  <div v-if="debugmode">
    <fw-button type="transparent" label="Debug" class="mr-2" @click.native="active = true">
      <fw-icon-code-slash class="w-5 h-5 opacity-80"></fw-icon-code-slash>
    </fw-button>
    <b-modal
      :active="active"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="800"
      :on-cancel="
        () => {
          active = false
        }
      "
      :custom-class="'rounded-buefy-modal'"
    >
      <div class="bg-white p-5 rounded-xl text-sm break-all min-w-fit">
        <div class="mb-4">
          <fw-label marginless uppercase>Debug</fw-label>
        </div>
        <div>
          <slot />
        </div>
        <fw-panel-info label="Authenticated user data (raw)" class="mt-4">
          <json-viewer :value="user"></json-viewer>
        </fw-panel-info>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'BlockHeaderDebug',
  data() {
    return {
      active: false,
    }
  },
  computed: {
    debugmode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    user() {
      return this.$store.getters.getUser
    },
  },
}
</script>
