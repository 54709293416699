<template>
  <div
    class="fw-panel-box flex flex-col relative py-2 gap-2 rounded-xl"
    :class="[
      {
        'bg-white': type === 'white',
        'bg-gray-100': type === 'light',
        'bg-yellow-600 bg-opacity-10': type === 'orange',
      },
    ]"
  >
    <div v-if="title || $slots.toolbar" class="flex gap-5 items-center flex-shrink-0 px-4 min-h-9">
      <div class="flex-1">
        <div v-if="title" class="flex items-center gap-3">
          <component :is="'fw-icon-' + icon" v-if="icon" class="w-5 h-5 flex-shrink-0"></component>
          <fw-heading class="relative" size="md">
            {{ title || 'Bloco' }}
            <fw-badge v-if="counter" inline type="simple" class="text-gray-500">
              {{ counter }}{{ counterTotal ? ' / ' + counterTotal : '' }}
            </fw-badge>
          </fw-heading>
          <div v-if="loading">
            <fw-icon-loading class="w-5 h-5" />
          </div>
          <div v-if="checkboxActive">
            <fw-icon-checkbox-circle class="w-5 h-5 text-primary" />
          </div>
        </div>
      </div>
      <div v-if="$slots.toolbar">
        <slot name="toolbar" />
      </div>
    </div>
    <div
      v-if="$slots.default"
      class="rounded-xl overflow-auto px-4"
      :class="[height ? 'height-' + height : '', innerClass]"
    >
      <slot />
    </div>
    <div v-if="$slots.footer" class="mt-2 px-4">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FwPanelBox',

  props: {
    type: {
      type: String,
      default: 'light',
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    counter: {
      type: [String, Number],
      default: null,
    },
    counterTotal: {
      type: [String, Number],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingError: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    afterLoadingChecked: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: null,
    },
    innerClass: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      checkboxActive: this.checked,
    }
  },

  watch: {
    loading(value, previous) {
      this.checkboxActive = false
      if (previous && !value && this.afterLoadingChecked && !this.loadingError) {
        this.checkboxActive = true
        setTimeout(() => {
          this.checkboxActive = false
        }, 3000)
      }
    },
  },
}
</script>

<style lang="postcss">
.fw-panel-box .height-xl {
  height: 48rem;
  overflow: auto;
}
.fw-panel-box .height-md {
  height: 32rem;
  overflow: auto;
}
.fw-panel-box .height-sm {
  height: 20rem;
  overflow: auto;
}
.fw-panel-box .height-xs {
  height: 10rem;
  overflow: auto;
}
.fw-panel-box .height-2xs {
  height: 5rem;
  overflow: auto;
}
</style>
