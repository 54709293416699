<template>
  <div
    class="font-sans antialiased h-screen flex flex-col"
    :class="{
      'bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800': dark,
      'bg-gray-50': !dark,
    }"
  >
    <header
      v-if="header"
      class=" top-0 left-0 w-full flex flex-none mx-auto items-center justify-center h-14 sm:h-16"
      style="z-index: 3"
      :class="{ 'text-white': dark, 'border-b border-gray-200': bordered }"
    >
      <div class="flex flex-none h-auto w-full px-5 py-2 lg:py-5">
        <div class="h-10 overflow-hidden min-w-fill flex items-center p-2 mr-4">
          <AppLogo
            :class="{ 'text-white': dark, 'text-black': !dark, 'cursor-pointer': listenBackEvent || !disableBackTo }"
            :size="!full && forceExtendedAppLogo && !shortLogo && window.width >= 640 ? 'lg' : 'xs'"
            @click.native="checkBackToAndGo"
          />
        </div>
        <div class="flex-1 h-18 flex items-center justify-between text-black">
          <div class="w-full">
            <span v-if="title && isMobile" class="font-bold line-clamp-1 text-md text-center">{{ title }}</span>
            <div :class="{ 'flex items-center gap-2': headerSwitchManage }">
              <BlockHeaderSwitchManage v-if="headerSwitchManage" :has-permissions="hasManagePermissions" />
              <slot name="header-nav"></slot>
            </div>
          </div>
          <div
            v-if="
              $slots.headerToolbarPrefix || $slots.mHeaderToolbar || headerToolbar || headerSessionNav || headerAvatar
            "
            class="pl-4 flex-shrink-0 flex align-middle justify-end space-x-3"
          >
            <div v-if="$slots.headerToolbarPrefix" class="flex items-center gap-2">
              <slot name="headerToolbarPrefix"></slot>
            </div>
            <div v-if="$slots.mHeaderToolbar" class="flex items-center sm:hidden gap-2">
              <slot name="m-header-toolbar"></slot>
            </div>
            <div v-if="headerToolbar" class="flex items-center">
              <BlockHeaderDebug v-if="$slots.debug && debugmode">
                <slot name="debug"></slot>
              </BlockHeaderDebug>
              <slot name="header-toolbar"></slot>
              <BlockHeaderNotifications v-if="headerNotifications && userLoggedIn && !isAnonymous" />
              <BlockHeaderApps v-if="headerApps && userLoggedIn && !isAnonymous" />
              <slot name="header-toolbar-end"></slot>
            </div>
            <div v-if="headerSessionNav && userLoggedIn && !isMobile && !isAnonymous">
              <b-dropdown aria-role="list" scrollable position="is-bottom-left">
                <div slot="trigger" class="flex is-link items-center justify-center">
                  <Avatar size="sm" :user="user" borderless />
                  <fw-icon-chevron-down class="w-4 h-4 ml-1"></fw-icon-chevron-down>
                </div>
                <b-dropdown-item custom aria-role="menuitem">
                  <div>
                    <v-clamp class="font-bold" autoresize :max-lines="2">{{
                      user.display_name || user.full_name
                    }}</v-clamp>
                    <div v-if="user.number" class="text-gray-500">Nº {{ user.number }}</div>
                    <v-clamp class="text-sm text-gray-500" autoresize :max-lines="1">{{ user.email }}</v-clamp>
                  </div>
                </b-dropdown-item>
                <hr class="dropdown-divider" />
                <b-dropdown-item has-link aria-role="menuitem">
                  <router-link to="/account" tag="a">{{ $t('profile') }}</router-link>
                  <a href="https://ucframework.pt" target="_blank">{{ $t('helpAndSupport') }}</a>
                  <router-link to="/logout" tag="a">{{ $t('logout') }}</router-link>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div v-if="!headerSessionNav && headerAvatar && userLoggedIn && !isMobile && !isAnonymous">
              <Avatar size="sm" :user="user" borderless />
            </div>
            <div v-if="headerSessionNav && !userLoggedIn && $router.currentRoute.name != login_route_name">
              <fw-button @click.native="loginClick"> {{ $t('login') }} </fw-button>
            </div>
          </div>
        </div>
      </div>
    </header>

    <main
      class="flex w-full h-auto flex-1"
      :class="{ 'text-white': dark, 'overflow-y-auto': !noScroll, 'overflow-y-hidden': noScroll }"
    >
      <slot name="main">
        <div
          v-if="mainSidebar || mainSidebarSm"
          class="main-sidebar h-full fixed top-0 pt-16 hidden lg:block"
          :class="[{ fixed: mainSidebarFixed }, mainSideBarComputed]"
        >
          <slot name="main-sidebar"></slot>
        </div>
        <div
          class="main-content flex-1"
          :class="{
            'xl:max-w-screen-xl mx-auto': !full && !wide,
            'xl:max-w-screen-2xl mx-auto': !full && wide,
            'pt-4 lg:pl-56': mainSidebar && !full && paddingless,
            'p-4 lg:pl-56': mainSidebar && !full && !paddingless && mainSideBarComputed != 'w-72',
            'p-4 lg:pl-72': mainSidebar && !full && !paddingless && mainSideBarComputed == 'w-72',
            'lg:pl-12 lg:pr-12': !mainSidebar && !full,
            'flex flex-col flex-1 pr-0': full,
            'flex flex-1 pr-0 pt-0 pb-0': fullcourses,
            'lg:pl-20': full && mainSidebarSm,
            'lg:pl-64': full && !mainSidebarSm && mainSidebar,
            'lg:pr-20': full && secondarySidebarSm,
            'lg:pr-64': full && !secondarySidebarSm && secondarySidebar,
            'lg:pr-56': !full && secondarySidebarSm,
            'lg:pr-56': !full && !secondarySidebarSm && secondarySidebar,
          }"
        >
          <div
            class="flex-1"
            :class="{
              'h-full': fullHeight,
              'min-h-fit': !full,
            }"
          >
            <slot name="main-content"></slot>
            <footer
              v-if="footer"
              class="text-xs border-t mt-10 text-gray-600 justify-center items-center gap-1 px-5 py-3"
            >
              <div class="font-normal flex flex-col lg:flex-row gap-1 lg:gap-5 lg:justify-between">
                <div class="flex flex-col gap-1.5">
                  <div>
                    Precisa de ajuda técnica? Envie-nos uma mensagem para
                    <a :href="`mailto:support@ucframework.pt`" class="font-semibold">support@ucframework.pt</a>
                  </div>
                  <div class="text-gray-500">
                    <span class="uppercase">{{ appName }} {{ appVersion }}</span>
                    <span class="mx-1">
                      {{ $t('by') }} <a href="https://ucframework.pt" target="_blank">UC Framework</a>
                    </span>
                    <span>{{ currentYear }} © {{ $t('university') }}</span>
                  </div>
                </div>
                <div class="lg:text-right lg:flex lg:flex-col lg:gap-4 text-gray-500">
                  <div>
                    <a target="_blank" href="https://www.uc.pt/sobrenos/localizacao_contactos" class="pr-2 py-2">{{
                      $t('contacts')
                    }}</a>
                    <a target="_blank" href="https://www.uc.pt/go/denuncia" class="p-2">{{ $t('reportingChannel') }}</a>
                    <a target="_blank" href="https://apps.uc.pt/forms/view/melhoria" class="p-2">{{
                      $t('complaintsAndSugestions')
                    }}</a>
                    <a target="_blank" href="https://www.uc.pt/avisolegal" class="p-2">{{ $t('legalDisclaimer') }}</a>
                    <a target="_blank" href="https://www.uc.pt/protecao-de-dados" class="pl-2 py-2">{{
                      $t('dataProtection')
                    }}</a>
                  </div>
                  <div v-if="!userLoggedIn" class="hidden lg:flex justify-end flex-shrink-0">
                    <img class="h-6" src="@/fw-modules/fw-core-vue/ui/images/sponsors/sponsors-fse-v02-low.png" />
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
        <div
          v-if="secondarySidebar || secondarySidebarSm"
          class="secondary-sidebar h-full fixed right-0 top-0 pt-16 hidden lg:block text-right"
          :class="[{ fixed: secondarySidebarFixed }, secondarySideBarComputed]"
        >
          <slot name="secondary-sidebar"></slot>
        </div>
      </slot>
    </main>
    <div
      v-if="tapbar || this.$slots['tapbar'] || keepTapbar"
      id="tapbar-slot"
      class="block inset-x-0 bottom-0"
      :class="{ 'bg-gray-900 text-white': dark, 'bg-gray-100': !dark, 'lg:hidden': !keepTapbar }"
    >
      <slot name="tapbar"></slot>
    </div>
    <slot name="modals"></slot>
  </div>
</template>

<script>
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import AppLogo from '@/components/AppLogo'
import Avatar from '@/fw-modules/fw-core-vue/ui/components/users/Avatar'
import BlockHeaderNotifications from '@/fw-modules/fw-core-vue/notifications/components/blocks/BlockHeaderNotifications'
import BlockHeaderDebug from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockHeaderDebug'
import BlockHeaderApps from '@/fw-modules/fw-core-vue/id/components/blocks/BlockHeaderApps'
import BlockHeaderSwitchManage from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockHeaderSwitchManage'
import LayoutBase from './LayoutBase'

export default {
  components: {
    AppLogo,
    Avatar,
    BlockHeaderNotifications,
    BlockHeaderApps,
    BlockHeaderDebug,
    BlockHeaderSwitchManage,
  },

  mixins: [LayoutBase],
  props: {
    // Back to page
    // Show arrow to return to previous page
    backTo: {
      type: String,
      default: null,
    },

    listenBackEvent: {
      type: Boolean,
      default: false,
    },

    disableBackHome: {
      type: Boolean,
      default: false,
    },

    paddingless: {
      type: Boolean,
      default: false,
    },

    disableBackTo: {
      type: Boolean,
      default: false,
    },

    // Page title - on mobile, set on the header
    title: {
      type: String,
      default: null,
    },

    dark: {
      type: Boolean,
      default: false,
    },

    // Show main sidebar
    full: {
      type: Boolean,
      default: false,
    },

    wide: {
      type: Boolean,
      default: false,
    },

    forceExtendedAppLogo: {
      type: Boolean,
      default: false,
    },

    shortLogo: {
      type: Boolean,
      default: false,
    },

    // Show main sidebar
    sidebar: {
      type: Boolean,
      default: false,
    },

    // Show main sidebar
    mainFull: {
      type: Boolean,
      default: false,
    },

    // Show main sidebar
    mainSidebar: {
      type: Boolean,
      default: false,
    },

    mainSidebarSm: {
      type: Boolean,
      default: false,
    },

    mainSidebarSize: {
      type: String,
      default: 'w-56',
    },

    mainSidebarFixed: {
      type: Boolean,
      default: false,
    },

    // Show secondary sidebar
    secondarySidebar: {
      type: Boolean,
      default: false,
    },

    secondarySidebarSm: {
      type: Boolean,
      default: false,
    },

    secondarySidebarSize: {
      type: String,
      default: 'w-56',
    },

    secondarySidebarFixed: {
      type: Boolean,
      default: false,
    },

    footer: {
      type: Boolean,
      default: false,
    },

    tapbar: {
      type: Boolean,
      default: false,
    },

    header: {
      type: Boolean,
      default: true,
    },

    keepTapbar: {
      type: Boolean,
      default: false,
    },

    headerToolbar: {
      type: Boolean,
      default: true,
    },

    // Show header user session dropdown
    headerSessionNav: {
      type: Boolean,
      default: true,
    },

    headerAvatar: {
      type: Boolean,
      default: false,
    },

    headerApps: {
      type: Boolean,
      default: true,
    },

    headerNotifications: {
      type: Boolean,
      default: true,
    },

    headerSwitchManage: {
      type: Boolean,
      default: false,
    },

    hasManagePermissions: {
      type: Boolean,
      default: false,
    },

    noScroll: {
      type: Boolean,
      default: false,
    },

    fullHeight: {
      type: Boolean,
      default: false,
    },

    bordered: {
      type: Boolean,
      default: false,
    },

    fullcourses: {
      type: Boolean,
      default: false,
    },

    // Deal with fullpage loading, notfound, mobile...
    fullPageLoading: {
      type: Boolean,
      default: false,
    },

    fullPageLoadingIcon: {
      type: String,
      default: 'compass',
    },

    fullPageLoadingTitle: {
      type: String,
      default: process.env.VUE_APP_KEY,
    },

    desktopOnly: {
      type: Boolean,
      default: false,
    },

    throwNotFound: {
      type: Boolean,
      default: false,
    },

    throwForbidden: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    debugmode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    mainSideBarComputed() {
      return this.mainSidebarSm ? 'w-20' : this.mainSidebarSize
    },
    secondarySideBarComputed() {
      return this.secondarySidebarSm ? 'w-20' : this.secondarySidebarSize
    },
    userLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    user() {
      return this.$store.getters.getUser
    },
    isAnonymous() {
      return this.user && this.user.isAnonymous
    },
    isMobile() {
      return this.window.width < 640
    },
    isMobileOrSmallTablet() {
      return window.innerWidth < 1024
    },
    language() {
      return this.$store.state.language
    },
    unread() {
      return this.$store.getters.getUnreadNotificationsCount
    },
    notifications() {
      return this.$store.getters.getUnreadNotifications
    },
    currentYear() {
      const now = new Date()
      return now.getFullYear()
    },
    login_route_name() {
      return process.env.VUE_APP_KEY == 'ucid' ? 'home' : 'login'
    },
  },
  methods: {
    getBackTo() {
      if (this.backTo == '-1') this.$router.back()
      if (this.backTo == 'emit') this.$emit('go-back-to')
      else this.$router.push(this.backTo)
    },
    checkBackToAndGo() {
      if (this.listenBackEvent) {
        this.$emit('back')
      }
      if (!this.disableBackTo) {
        if (this.backTo) this.getBackTo()
        else this.$router.push('/')
      }
    },
    goToNotification(key) {
      const route = this.$router.currentRoute
      if (!route || route.name != 'notification' || route.params.key != key) {
        this.$router.push({ name: 'notification', params: { key: key } })
      }
    },
    loginClick() {
      if (FwEnvConfig.appsUCIdSSO.includes(process.env.VUE_APP_KEY) && FwEnvConfig.appUrlUCId) {
        window.location.href = `${FwEnvConfig.appUrlUCId}/${process.env.VUE_APP_KEY}`
      } else {
        this.$router.push({ name: this.login_route_name })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
header {
  button {
    min-height: 35px;
    min-width: 2.5rem;
    @apply rounded-xl bg-transparent;
  }
}
.z-5 {
  z-index: 5;
}
</style>

<i18n>
{
  "pt": {
    "login": "Entrar",
    "profile": "Perfil",
    "helpAndSupport": "Ajuda e suporte",
    "logout": "Terminar sessão",
    "contacts":"Contactos",
    "reportingChannel": "Canal de Denúncia",
    "complaintsAndSugestions": "Elogios, Sugestões e Reclamações",
    "legalDisclaimer": "Aviso Legal",
    "dataProtection": "Proteção de Dados",
    "university": "Universidade de Coimbra",
    "by": "por"
  },
  "en": {
    "login": "Login",
    "profile": "Profile",
    "helpAndSupport": "Help and support",
    "logout": "Logout",
    "contacts": "Contacts",
    "reportingChannel": "Reporting Channel",
    "complaintsAndSugestions": "Compliments, Suggestions and Complaints",
    "legalDisclaimer": "Legal Disclaimer",
    "dataProtection": "Data Protection",
    "university": "Coimbra University",
    "by": "by"
  }
}
</i18n>
