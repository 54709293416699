var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'px-1 py-0.5 text-center rounded-full font-semibold': _vm.type == 'rounded',
    'text-xs bg-opacity-50 font-normal leading-3 inline-flex': _vm.type == 'simple',
    'bg-teal-500 text-white bg-opacity-90': _vm.color == 'primary' && _vm.type != 'simple',
    'bg-red-600 bg-opacity-90 text-white': _vm.color == 'red' && _vm.type != 'simple',
    'bg-gray-500 bg-opacity-20 text-gray-600': _vm.color == 'light' && _vm.type != 'simple',
    'bg-gray-700 bg-opacity-60 text-gray-300': _vm.color == 'dark' && _vm.type != 'simple',
    'absolute -top-1 -right-2': !_vm.inline,
    'p-1': _vm.inline,
    'p-2': !_vm.inline && _vm.type == 'simple',
    'text-xs': _vm.size == 'xs',
    'text-sm': _vm.size == 'sm',
  },style:(_vm.type == 'rounded' ? 'min-width: 18px;' : '')},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }