var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row_class"},[_c('label',{staticClass:"fw-row-checkbox relative select-none"},[(_vm.selectable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentValue),expression:"currentValue"}],staticClass:"opacity-0 absolute",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.currentValue)?_vm._i(_vm.currentValue,null)>-1:(_vm.currentValue)},on:{"change":[function($event){var $$a=_vm.currentValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.currentValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.currentValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.currentValue=$$c}},_vm.selectClass]}}):_vm._e(),_c('div',{staticClass:"fw-row-checkbox-inside flex w-full gap-1 items-center relative fw-card-file justify-between p-2 my-1 group rounded-lg bg-opacity-10 shadow-sm",class:{
        'hover:bg-opacity-40': _vm.clickable,
        'hover:shadow-xl': _vm.clickable,
        'cursor-pointer': _vm.clickable || _vm.selector,
        border: _vm.selector,
        'border-gray-200': _vm.selector,
        'hover:shadow-md': _vm.selector,
      }},[(_vm.selector)?_c('svg',{staticClass:"fill-current absolute top-4 right-4 h-4 w-4 text-gray-700",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M12 8l6 6H6z"}})]):_vm._e(),(_vm.selector)?_c('svg',{staticClass:"fill-current absolute bottom-4 right-4 h-4 w-4 text-gray-700",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M12 16l-6-6h12z"}})]):_vm._e(),(_vm.selectable)?_c('div',{staticClass:"check h-4 w-4 rounded p-1"}):_vm._e(),_c('div',{staticClass:"flex-1 text-sm md:text-base ml-1"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex"},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.classTitle)+" ")])],1)]),_c('div',{staticClass:"text-xs md:text-sm flex opacity-60 gap-1 justify-start leading-5"},[_c('div',[_vm._v(_vm._s(_vm.unitTitle))]),(_vm.unitTitle.length > 0 && _vm.profile.length > 0)?_c('div',[_vm._v(" - ")]):_vm._e(),(_vm.profile.length > 0)?_c('div',[_vm._v(_vm._s(_vm.profile))]):_vm._e(),(_vm.mainCourse.length > 0)?_c('div',[_vm._v(" - "+_vm._s(_vm.mainCourse))]):_vm._e(),(_vm.numExtraCourses > 0)?_c('div',[_vm._v("+ "+_vm._s(_vm.numExtraCourses))]):_vm._e(),(_vm.ocorrence !== '')?_c('span',{staticClass:"text-xs font-normal leading-3 inline-flex bg-gray-300 bg-opacity-60 text-gray-600 rounded-full align-middle items-center m-0.5 ml-2 px-2"},[_vm._v(_vm._s(_vm.ocorrence))]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }