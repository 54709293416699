var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"flex",class:[{ 'absolute top-1 right-1': !_vm.inline, relative: _vm.inline }, _vm.wh]},[_c('span',{staticClass:"absolute inline-flex h-full w-full rounded-full opacity-75",class:{
      'animate-ping': _vm.ping,
      'bg-primary': _vm.color === 'primary',
      'bg-yellow-600': _vm.color === 'orange',
      'bg-red-600': _vm.color === 'danger',
      'bg-white': _vm.color === 'white',
      'bg-gray-500 opacity-20': _vm.color === 'light',
    }}),_c('span',{staticClass:"relative inline-flex rounded-full",class:[
      {
        'bg-primary': _vm.color === 'primary',
        'bg-white': _vm.color === 'white',
        'bg-red-600': _vm.color === 'danger',
        'bg-yellow-600': _vm.color === 'orange',
        'bg-gray-500 opacity-20': _vm.color === 'light',
      },
      _vm.wh,
    ]})])
}
var staticRenderFns = []

export { render, staticRenderFns }