<template>
  <div class="chat-message my-1 group" :class="{ isSelf: isSelf, isNotSelf: !isSelf }" @mouseleave="showCmds = false">
    <div class="w-full">
      <div class="flex gap-2 items-end" :class="{ 'justify-end': isSelf }">
        <UserAvatar
          v-if="!oneToOneChat"
          :user="user"
          size="xs"
          class="flex-shrink-0"
          :class="{ 'order-last': isSelf }"
        />
        <div class="flex items-center text-sm">
          <div
            class="px-2 py-1 font-medium"
            :class="{
              'text-white bg-primary': isSelf,
              'bg-gray-50 text-gray-800': !isSelf,
              'rounded-tl-lg rounded-r-lg': !oneToOneChat && !isSelf,
              'rounded-l-lg rounded-tr-lg': !oneToOneChat && isSelf,
              'rounded-lg': oneToOneChat,
              'order-last': isSelf,
            }"
          >
            <div class="flex justify-between gap-2 items-center">
              <v-clamp v-if="!isSelf" autoresize :max-lines="1" class="font-bold flex-1 text-sm mb-1">
                {{ user.name }}
              </v-clamp>
              <div v-if="isTranslated" class="pl-2">
                <fw-button
                  v-if="!showOriginal"
                  size="sm"
                  class="flex items-center gap-1 text-primary"
                  @click.native="showOriginal = true"
                >
                  <fw-icon-translate class="w-5 h-5" />
                </fw-button>
                <fw-button
                  v-else
                  size="sm"
                  class="flex items-center gap-1 text-primary"
                  @click.native="showOriginal = false"
                >
                  <fw-icon-translate class="w-5 h-5" />
                  Traduzir
                </fw-button>
              </div>
            </div>
            <div class="flex gap-2 text-base">
              <fw-icon-robot v-if="isTranslated && !showOriginal" class="w-5 h-5 text-primary flex-shrink-0" />
              <div
                class="break-words fix-links-overflow"
                :class="{ 'text-primary': !showOriginal && !isSelf }"
                v-html="isTranslated && !showOriginal ? message.translated : parseMessage(message.message)"
              ></div>
            </div>
            <div class="flex-1 text-xs mt-1 opacity-60">{{ message.created_date | formatDateTime }}</div>
          </div>
          <div class="mx-4 opacity-70 group-hover:opacity-90 flex flex-shrink-0 gap-2">
            <fw-button
              v-if="canDeleteAll || loggedUser.key == user.key"
              type="white"
              size="xs"
              class="opacity-50 hover:opacity-100"
              @click.native="showCmds = !showCmds"
            >
              <fw-icon-more class="w-5 h-5" />
            </fw-button>
            <fw-button
              v-if="(canDeleteAll || loggedUser.key == user.key) && showCmds"
              type="white"
              size="xs"
              @click.native="$emit('delete-message', chatKey, message.key)"
            >
              <fw-icon-trash class="w-5 h-5 text-primary" />
            </fw-button>
          </div>
        </div>
      </div>

      <!-- Attachments  and multimedia -->
      <div
        v-if="message.files.length || message.recordings.length || message.quiz.length || message.bucketItems.length"
        class="mt-4 mb-6 mx-auto max-w-screen-lg"
      >
        <div v-if="message.files.length" class="p-3 bg-white bg-opacity-50 rounded-xl grid grid-cols-1 gap-3">
          <div v-for="file in message.files" :key="file.key" class="group">
            <div class="text-gray-500 opacity-40 flex items-center gap-1 text-xs">
              <fw-icon-attachment class="w-5 h-5 inline-flex" />
              <fw-label>CID {{ file.key }}</fw-label>
            </div>
            <div>
              <div v-if="file.type === 'image'">
                <div class="rounded overflow-hidden text-sm">
                  <a v-if="allowDownloads" target="_blank" :href="getFileUrl(file)"
                    ><img :src="getImageViewUrl(file)"
                  /></a>
                  <img v-else :src="getImageViewUrl(file)" />
                </div>
                <div
                  v-if="false"
                  class="has-margin-top ai-persons has-padding-left has-padding-right has-padding-top has-background-white"
                >
                  <div class="label is-marginless">Pessoas encontradas</div>
                  <div v-for="person in file.persons" :key="person.$index" class="record">
                    <div class="record-icon">
                      <UserAvatar />
                    </div>
                    <div class="record-content">
                      <div v-if="person.user" class="record-title is-size-4">
                        {{ person.user.name }}
                      </div>
                      <div class="has-text-small">
                        {{ person.metadata }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="file.type === 'video'">
                <video-player :ref="'v-' + file.key" :src="getFileViewUrl(file)" />
              </div>
              <div v-else>
                <a
                  v-if="allowDownloads"
                  target="_blank"
                  :href="getFileUrl(file)"
                  class="font-bold text-sm text-primary py-1 inline-flex"
                >
                  <fw-icon-download class="w-5 h-5 mr-2 mt-0.5" />
                  <v-clamp autoresize :max-lines="1">
                    {{ file.filename }}
                  </v-clamp>
                </a>
                <div v-else class="font-bold text-sm text-gray-500 py-1 inline-flex">
                  <fw-icon-lock class="w-5 h-5 mr-2 mt-0.5" />
                  <v-clamp autoresize :max-lines="1">
                    {{ file.filename }}
                  </v-clamp>
                </div>
              </div>
            </div>
            <div v-if="file.type === 'image'" class="mt-2 opacity-70 group-hover:opacity-100 text-gray-500">
              <div v-if="file.labels && file.labels.length" class="flex gap-2 items-start">
                <div class="flex-shrink-0">
                  <fw-icon-robot class="w-5 h-5" />
                </div>
                <div class="flex items-center gap-x-2 text-sm font-medium flex-wrap">
                  <span v-for="label in file.labels" :key="label.$index">{{ label }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="message.recordings.length || message.quiz.length || message.bucketItems.length"
          class="px-5 pt-2 pb-5 bg-white bg-opacity-50 rounded-xl"
        >
          <div v-if="message.recordings.length" class="group">
            <div v-for="rec in message.recordings" :key="rec.key">
              <div class="text-gray-500 opacity-40 flex items-center gap-1 text-xs">
                <fw-icon-attachment class="w-5 h-5 inline-flex" />
                <fw-label>CID mrec-{{ rec.key }}</fw-label>
              </div>
              <video-player
                :ref="'mrec-' + rec.key"
                :src="getFileViewUrl(rec.file)"
                :poster="recordingsPosterURL(rec)"
              />
            </div>
          </div>
          <div v-if="message.quiz.length">
            <div class="text-gray-500 opacity-40 flex items-center gap-1 text-xs">
              <fw-icon-attachment class="w-5 h-5 inline-flex" />
            </div>
            <div v-for="quiz in message.quiz" :key="quiz.key">
              <h1>{{ quiz.title }}</h1>
              <div><b>Users:</b> {{ quiz.users_count }}</div>
              <div><br /><b>Não respondeu:</b> {{ quiz.no_answer.count }}</div>
              <div><br /><b>Sem resposta:</b> {{ quiz.answers[0].none.count }}</div>
              <div v-for="(option, index) in quiz.answers[0].options" :key="index">
                <br /><b>{{ option.title }}:</b> {{ option.count }}
              </div>
            </div>
          </div>
          <div v-if="message.bucketItems.length" class="group">
            <div v-for="item in message.bucketItems" :key="item.key">
              <div class="text-gray-500 opacity-40 flex items-center gap-1 text-xs">
                <fw-icon-attachment class="w-5 h-5 inline-flex" />
                <fw-label>CID {{ item.key }}</fw-label>
              </div>
              <video-player
                v-if="item.metadata.file_type == 'video'"
                :ref="'cid-' + item.key"
                :options="{
                  muted: true,
                  fluid: true,
                  responsive: true,
                  language: 'pt',
                  preload: 'none',
                  html5: {
                    nativeVideoTracks: true,
                  },
                  controlsList: item.lock_download ? 'nodownload' : null,
                  poster: null,
                  sources: [
                    {
                      type: 'video/mp4',
                      src: getFileViewUrl(item.file),
                    },
                  ],
                }"
              >
              </video-player>
              <div v-else-if="allowDownloads">
                <a target="_blank" :href="getFileViewUrl(item.file, true)">Download</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Autolinker from 'autolinker'
import ChatUtils from '@/fw-modules/fw-core-vue/chats/services/utilities'
import VideoPlayer from '@/fw-modules/fw-core-vue/ui/components/video/VideoPlayer'
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    UserAvatar,
    VideoPlayer,
  },

  props: {
    message: Object,
    chatType: String,
    chatKey: String,
    user: Object,
    canDeleteAll: Boolean,
    aiTranslateOn: Boolean,
    allowDownloads: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showOriginal: !this.aiTranslateOn,
      showCmds: false,
    }
  },

  computed: {
    isTranslated() {
      return this.message.translated && this.message.message != this.message.translated
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
    oneToOneChat() {
      return this.chatType == 'user' || false
    },
    isSelf() {
      return this.loggedUser.key == this.user.key
    },
  },

  watch: {
    aiTranslateOn(value) {
      this.showOriginal = !value
    },
  },

  methods: {
    getFileUrl(file) {
      return ServiceStorage.getFileUrl(file)
    },
    getFileViewUrl(file, download = false) {
      return ServiceStorage.getFileViewUrl(file, download)
    },
    getImageViewUrl(file) {
      return ServiceStorage.getImageViewUrl(file)
    },
    recordingsPosterURL(recording) {
      return utils.recordingsPosterURL(recording)
    },

    parseMessage(content) {
      return Autolinker.link(content, {
        newWindow: true,
        stripPrefix: false,
      })
    },

    // Video player
    onPlayerPlay(player) {
      console.debug('player play!', player)
    },
    onPlayerPause(player) {
      console.debug('player pause!', player)
    },
    playerStateChanged(playerCurrentState) {
      console.debug('player current update state', playerCurrentState)
    },
    playerReadied(player) {
      console.debug('the player is readied', player)
      if (this.chatActive) {
        ChatUtils.scrollToBottom(10, () => {
          this.chatActive.selfUnread = 0
        })
      }
    },
  },
}
</script>

<style lang="scss">
.chat-message {
  .text-primary {
    * {
      @apply text-primary text-base;
    }
  }
  pre {
    @apply bg-opacity-50 bg-white p-4 rounded-xl;
  }
  .fix-links-overflow {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
  }
}
.chat-message.isSelf {
  strong {
    @apply text-white;
  }
}
.chat-message.isNotSelf {
  strong {
    @apply text-gray-800;
  }
}
</style>
