<template>
  <div class="flex flex-col h-full">
    <div ref="listHeader" class="flex-shrink-0 px-5 w-full" :class="{ 'xl:max-w-screen-xl mx-auto': !isModal }">
      <div
        v-if="!applicationContext"
        class="flex flex-col sm:flex-row gap-4"
        :class="{ 'mb-5': maxScrollHeight === -1 && autoParentHeight === -1 }"
      >
        <div class="flex flex-1 items-end gap-3 overflow-x-auto hide-scrollbar">
          <fw-button
            v-for="filter in filters"
            :key="filter.key"
            :type="filter.key === filterByType ? 'tab-active' : 'tab'"
            @click.native="changeFilter(filter.key)"
          >
            <v-clamp autoresize :max-lines="1">
              {{ filter.label }}
            </v-clamp>
            <span
              v-if="filter.new_label === true"
              class="text-xs bg-gray-100 text-teal-600 border border-teal-600 px-1.5 leading-4 py-0.5 ml-1.5 font-bold rounded opacity-60"
            >
              NOVO
            </span>
          </fw-button>
        </div>
        <div class="flex flex-shrink-0 gap-3 items-center justify-end">
          <div v-if="showViewOptions" class="flex gap-1 items-center">
            <b-tooltip label="Vista em lista" type="is-light">
              <fw-button
                :class="{ 'text-primary': list_view == 'row', 'text-gray-700': list_view != 'row' }"
                label="Ver lista"
                @click.native="setView('row')"
              >
                <fw-icon-list-row class="w-5 h-5" />
              </fw-button>
            </b-tooltip>
            <b-tooltip label="Vista em cartões" type="is-light">
              <fw-button
                :class="{ 'text-primary': list_view == 'card', 'text-gray-700': list_view != 'card' }"
                label="Ver em cartões"
                @click.native="setView('card')"
              >
                <fw-icon-list-card class="w-5 h-5" />
              </fw-button>
            </b-tooltip>
            <span class="mx-2 w-1 h-4 border-l border-gray-500 opacity-50"></span>
          </div>
          <b-dropdown
            v-if="academicBucketTypes.includes(filterByType) || filterByType === 'classes_and_units'"
            ref="academicYearsDropdown"
            scrollable
            max-height="500"
            aria-role="list"
            position="is-bottom-left"
            class="h-full"
            :class="{
              'mb-1.5': isModal,
            }"
          >
            <fw-button
              v-if="selectedAcademicYear !== -1"
              slot="trigger"
              aria-label="Escolher ano letivo"
              type="dropdown"
              size="sm"
              class="flex gap-1 items-center"
            >
              <svg
                class="fill-current h-6 w-6 mr-1 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
              </svg>
              <div class="overflow-hidden h-5">{{ selectedSemester }}º Sem</div>
              <div class="bg-gray-300 h-1.5 w-1.5 rounded-full mx-1"></div>
              {{ selectedAcademicYear }}
              <fw-icon-chevron-down class="w-4 h-4" />
            </fw-button>
            <b-dropdown-item
              :key="'semester_label'"
              aria-role="menu-item"
              :focusable="false"
              class="font-semibold"
              paddingless
              custom
            >
              <fw-label class="ml-4">Semestre</fw-label>
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="menu-item"
              :focusable="true"
              class="px-4 py-2 font-semibold flex justify-start gap-3 items-center"
              @click.native="setSemester(1)"
            >
              <div
                class="border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5"
                :class="{
                  'bg-primary bg-opacity-90': selectedSemester === 1,
                  'bg-gray-200': selectedSemester !== 1,
                }"
              ></div>
              <div>1º Semestre</div>
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="menu-item"
              :focusable="true"
              class="px-4 py-2 font-semibold flex justify-start gap-3 items-center"
              @click.native="setSemester(2)"
            >
              <div
                class="border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5"
                :class="{
                  'bg-primary bg-opacity-90': selectedSemester === 2,
                  'bg-gray-200': selectedSemester !== 2,
                }"
              ></div>
              <div>2º Semestre</div>
            </b-dropdown-item>
            <b-dropdown-item
              :key="'academic_year_label'"
              aria-role="menu-item"
              :focusable="false"
              class="font-semibold"
              paddingless
              custom
            >
              <fw-label class="ml-4">Ano lectivo</fw-label>
            </b-dropdown-item>
            <b-dropdown-item
              v-for="(academicYear, a) in sortedAcademicYears"
              :key="'academic_year_' + a"
              aria-role="menu-item"
              :focusable="true"
              class="px-4 py-2 font-semibold flex justify-start gap-3 items-center"
              @click.native="setAcademicYear(academicYear, true, true)"
            >
              <div
                class="border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5"
                :class="{
                  'bg-primary bg-opacity-90': selectedAcademicYear === academicYear,
                  'bg-gray-200': selectedAcademicYear !== academicYear,
                }"
              ></div>
              <div>{{ academicYear }}</div>
            </b-dropdown-item>
          </b-dropdown>
          <button
            v-if="!isModal && createAllowed"
            :arial-label="'Criar novo repositório'"
            class="flex items-center gap-1 px-3 py-2 text-sm inline-flex flex items-center font-bold text-white transition-colors duration-150 bg-primary rounded-lg active:bg-opacity-75 hover:bg-opacity-75 focus:outline-none focus:shadow-outline-gray"
            @click="$router.push({ name: 'new-bucket' })"
          >
            Criar
          </button>
        </div>
      </div>
    </div>
    <div class="overflow-y-auto flex-1 overflow-x-hidden">
      <div
        v-if="!loading && bucketsFiltered.length && !isList"
        :key="
          filterByType +
            '_' +
            selectedAcademicYear +
            '_' +
            selectedAcademicYear +
            '_' +
            list_view +
            '_' +
            buckets.length
        "
        :class="{
          'px-0 pb-16 xl:max-w-screen-xl mx-auto': !isModal && paddingless,
          'px-5 pb-16 xl:max-w-screen-xl mx-auto': !isModal && !paddingless,
          'p-5': isModal,
          'darkScrollArea shadow-inner': overrideWithClassesList,
        }"
        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4"
      >
        <!-- Cards View -->
        <CardBucket v-for="bucket in bucketsFiltered" :key="bucket.key" :bucket="bucket" :app-name="appName" />
      </div>
      <div
        v-else-if="
          !loading &&
            unitsList.length > 0 &&
            buckets.length > 0 &&
            isList &&
            (overrideWithClassesList || joinUnitsAndClasses) &&
            filterByType === 'classes_and_units'
        "
        :key="filterByType + '_' + selectedAcademicYear + '_' + selectedAcademicYear + '_' + list_view"
        class="relative flex-1"
        :class="{
          'px-0 pb-10 xl:max-w-screen-xl mx-auto': !isModal && paddingless,
          'px-5 pb-10 xl:max-w-screen-xl mx-auto': !isModal && !paddingless,
          //autoScrollActive: maxScrollHeight !== -1,
          //'p-5 pt-0 mt-5': autoParentHeight !== -1,
          'darkScrollArea shadow-inner': overrideWithClassesList,
          'p-5': isModal,
        }"
      >
        <div v-for="(unit, u) in unitsListFiltered" :key="'unitoption_' + u" class="pb-5">
          <div
            class="flex  justify-start items-start content-start sm:items-center sm:flex-row gap-x-2 opacity-70"
            style="z-index: 2"
            :class="{
              'sticky top-0 ': !isModal,
            }"
          >
            <!-- removed: flex-col -->
            <h2 class="lg:text-md font-bold text-black">
              {{ unit.title }}
            </h2>
            <div
              v-if="isModal"
              class="text-xs font-normal leading-5 inline-flex bg-gray-300 bg-opacity-60 text-gray-800 rounded-full align-middle items-center px-2 -mb-0.5"
            >
              {{ unit.profile }}
            </div>
            <div class="flex-1 flex justify-end">
              <b-tooltip
                v-if="!isModal && unit.courses.length > 0"
                :label="unit.courses.join(', ')"
                type="is-light"
                :position="'is-left'"
                multilined
              >
                <svg
                  class="fill-current text-gray-700 h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"
                  />
                </svg>
                <!-- <template v-slot:content>
                <fw-label>Cursos</fw-label>
                <div
                  v-for="(course, c) in unit.courses"
                  :key="'course_label_' + c + '_' + u"
                  class="text-xs tetx-gray-500"
                >
                  {{ course }}
                </div>
              </template> -->
              </b-tooltip>
            </div>
          </div>
          <div class="flex justify-start hidden sm:block">
            <fw-label>Repositório(s) da disciplina</fw-label>
          </div>
          <div v-if="joinUnitsAndClasses || overrideWithClassesList">
            <RowBucket
              v-for="bucket in unit.unit_buckets"
              v-show="bucket.key !== ignoreBucket"
              :key="bucket.key"
              v-model="bucket.selected"
              :checked="bucket.selected"
              :bucket="bucket"
              :selectable="selectableList"
              :view="list_view"
              :show-key="isModal"
              @selected="
                emitSelectedClassBucket(bucket.key, $event, bucket.new_repo, bucket.type, unit.key, bucket.title)
              "
            />
          </div>
          <div v-else>
            <RowBucket
              v-for="bucket in getClassBuckets(unit.key, unit.title, true, unit.unit_key_for_bucket)"
              v-show="bucket.key !== ignoreBucket"
              :key="bucket.key"
              v-model="bucket.selected"
              :checked="bucket.selected"
              :bucket="bucket"
              :selectable="selectableList"
              :view="list_view"
              :show-key="isModal"
              @selected="
                emitSelectedClassBucket(bucket.key, $event, bucket.new_repo, bucket.type, unit.key, bucket.title)
              "
            />
          </div>

          <div v-for="(classe, c) in unit.classes" :key="'classeoption_' + u + '_' + c">
            <div
              v-if="
                ((joinUnitsAndClasses || overrideWithClassesList) &&
                  getClassBuckets(classe.key, classe.title, false).length > 0) ||
                  !(joinUnitsAndClasses || overrideWithClassesList)
              "
              class="flex flex-col justify-start pt-1"
            >
              <fw-label>{{ classe.title }}</fw-label>
              <div v-if="false" class="text-xs font-normal text-gray-600">
                <span>{{ classe.profile }}</span>
                <span v-if="classe.regime === 'semiannual'" class="ml-2">{{ classe.regime_occurrence }}º Semestre</span>
              </div>
            </div>
            <div
              v-if="
                (joinUnitsAndClasses || overrideWithClassesList) &&
                  getClassBuckets(classe.key, classe.title, false).length > 0
              "
            >
              <RowBucket
                v-for="bucket in getClassBuckets(classe.key, classe.title, false)"
                v-show="bucket.key !== ignoreBucket"
                :key="bucket.key"
                v-model="bucket.selected"
                :checked="bucket.selected"
                :bucket="bucket"
                :selectable="selectableList"
                :view="list_view"
                :show-key="isModal"
                @selected="
                  emitSelectedClassBucket(bucket.key, $event, bucket.new_repo, bucket.type, classe.key, bucket.title)
                "
              />
            </div>
            <div v-else-if="!(joinUnitsAndClasses || overrideWithClassesList)">
              <RowBucket
                v-for="bucket in getClassBuckets(classe.key, classe.title, false)"
                v-show="bucket.key !== ignoreBucket"
                :key="bucket.key"
                v-model="bucket.selected"
                :checked="bucket.selected"
                :bucket="bucket"
                :selectable="selectableList"
                :view="list_view"
                :show-key="isModal"
                @selected="
                  emitSelectedClassBucket(bucket.key, $event, bucket.new_repo, bucket.type, classe.key, bucket.title)
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="!loading && bucketsFiltered.length && isList"
        :key="filterByType + '_' + selectedAcademicYear + '_' + selectedAcademicYear + '_' + list_view"
        :class="{
          //autoScrollActive: maxScrollHeight !== -1,
          'px-0 pb-16 xl:max-w-screen-xl mx-auto': !isModal && paddingless,
          'px-5 pb-16 xl:max-w-screen-xl mx-auto': !isModal && !paddingless,
          'p-5': isModal && !paddingless,
          //'p-5': autoParentHeight !== -1,
        }"
      >
        <!-- List View -->
        <RowBucket
          v-for="bucket in bucketsFiltered"
          v-show="bucket.key !== ignoreBucket"
          :key="bucket.key"
          v-model="bucket.selected"
          :checked="bucket.selected"
          :bucket="bucket"
          :selectable="selectableList"
          :view="list_view"
          :show-key="isModal"
          @selected="emitSelected"
        />
      </div>
      <div
        v-else-if="!loading && (!buckets.length || !bucketsFiltered.length)"
        :class="{
          //'p-5 mx-5': autoParentHeight !== -1,
          'border border-gray-300 p-5 xl:max-w-screen-xl mx-auto': !isModal,
          'p-5 mt-5': isModal,
        }"
        class="flex flex-col text-center items-center justify-center text-gray-500 p-10 mb-5 rounded-xl"
      >
        <div v-if="filterByType && !isModal">Ainda não tem repositórios de ficheiros deste tipo.</div>
        <div v-else-if="filterByType && isModal">Não existem repositórios de ficheiros deste tipo.</div>
        <div v-else>Ainda não existem repositórios de ficheiros.</div>
        <div
          v-if="
            createAllowed &&
              !isModal &&
              filterByType !== 'classes_and_units' &&
              !academicBucketTypes.includes(filterByType)
          "
          class="flex flex-col items-center justify-center"
        >
          <fw-icon-box-open class="w-20 h-20 mt-10 opacity-75"></fw-icon-box-open>
          <div class="mx-auto max-w-prose font-medium text-center mt-5 text-base">
            Crie os repositórios de ficheiros que precisar para organizar os seus conteúdos digitais.
          </div>
          <fw-button class="group-hover:scale-110 text-primary" @click.native="$emit('go-to-new-bucket')">
            Criar novo
          </fw-button>
        </div>
      </div>
      <LoadingPlaceholder
        v-else
        :list="isList"
        :class="{
          'px-0 pb-16 xl:max-w-screen-xl mx-auto': !isModal && paddingless,
          'px-5 pb-16 xl:max-w-screen-xl mx-auto': !isModal && !paddingless,
          'p-5': isModal && !paddingless,
        }"
      ></LoadingPlaceholder>
    </div>
  </div>
</template>

<script>
import ServiceBuckets from '@/fw-modules/fw-core-vue/buckets/services/ServiceBuckets'
import CardBucket from '@/fw-modules/fw-core-vue/buckets/components/cards/CardBucket'
import RowBucket from '@/fw-modules/fw-core-vue/buckets/components/cards/RowBucket'
import LoadingPlaceholder from '../../../ui/components/animation/LoadingPlaceholder'
import ServiceAcademic from '../../../academic/services/ServiceAcademic'
//import Vue from 'vue'
import config from '@/fw-modules/fw-core-vue/config'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'

const rehidratedFiltersMap = {
  user: 'user',
  units: 'unit_edition',
  classes: 'class_edition',
  groups: 'groups',
  shared: 'shared',
  shared_with_me: 'shared_with_me',
  classes_and_units: 'classes_and_units',
}

export default {
  components: {
    CardBucket,
    RowBucket,
    LoadingPlaceholder,
  },

  props: {
    applicationContext: Object,
    appName: {
      type: String,
    },
    list: {
      type: Boolean,
      default: false, //TODO: change again to false
    },
    selectableList: {
      type: Boolean,
      default: false,
    },
    maxScrollHeight: {
      type: Number,
      default: -1,
    },
    autoScroll: {
      type: Boolean,
      default: false,
    },
    overrideWithClassesList: {
      //To show classes instead of classes buckets
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Array,
      default: function() {
        return []
      },
    },
    ignoreBucket: {
      type: String,
    },
    filterWithRoutes: {
      type: Boolean,
      default: false,
    },
    joinUnitsAndClasses: {
      type: Boolean,
      default: false,
    },
    showViewOptions: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      filterByType: !this.applicationContext ? '' : null,
      buckets: this.buckets || [],
      academicBucketTypes: ['class_edition', 'unit_edition', 'unit'],
      academicBucketsByYear: {
        class_edition: {
          years: {},
          selectedYear: null,
        },
        unit_edition: {
          years: {},
          selectedYear: null,
        },
        classes_and_units: {
          years: {},
          selectedYear: null,
        },
      },
      bucketsCache: {
        user: [],
        shared_with_me: [],
        groups: [],
      },
      groupedDataCache: {},
      unitsListCache: {},
      filters: [
        {
          key: '',
          label: 'Todos',
        },
        {
          key: 'user',
          label: 'Pessoais',
        },
        {
          key: 'shared',
          label: 'Partilhados comigo',
        },
        {
          key: 'groups',
          label: 'Grupos',
        },
        /*{
          key: 'unit_edition',
          label: 'Disciplinas',
        },
        {
          key: 'class_edition',
          label: 'Turmas',
        },*/
      ],
      unitsList: [],
      selectedAcademicYear: -1,
      selectedSemester: 1,
      academicYearOptions: [],
      bucketsByClassDicio: {},
      classBucketsSelected: new Set(),
      autoParentHeight: -1,
      list_view: 'card',
      loadedUserViewPreferences: false,
    }
  },

  computed: {
    sortedAcademicYears() {
      let academicYears = this.academicYearOptions
      return academicYears.sort().reverse()
    },
    currentAppName() {
      return this.$store.state.appName
    },
    user() {
      return this.$store.getters.getUser
    },
    isTeacher() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('teacher'))
    },
    isStudent() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('student'))
    },
    isList() {
      return this.list || this.list_view === 'row'
    },
    bucketsFiltered() {
      let buckets = this.buckets
      let compareFunction = function(a, b) {
        /*if ((a.type === 'unit_edition' || a.type === 'unit') && (b.type === 'unit_edition' || b.type === 'unit')) {
          return a['title'].localeCompare(b['title'])
        }*/
        if (a.type === 'unit' && b.type !== 'unit') {
          return -1
        }
        if (b.type === 'unit' && a.type !== 'unit') {
          return 1
        }

        if (a.type === 'unit_edition' && b.type === 'unit') {
          return 1
        }

        if ((a.type === 'unit_edition' || a.type === 'unit') && b.type !== 'unit_edition' && a.type !== 'unit') {
          return -1
        }
        if (a.type !== 'unit_edition' && a.type !== 'unit' && (b.type === 'unit_edition' || b.type === 'unit')) {
          return 1
        }
        return a['title'].localeCompare(b['title'])
      }
      if (!this.filterByType) {
        return buckets.sort(compareFunction)
      } else if (this.filterByType === 'user') {
        return (
          buckets
            /*.filter(bucket => {
            return !bucket.type && bucket.is_owner
          })*/
            .sort(compareFunction)
        )
      } else if (this.filterByType === 'shared') {
        return (
          buckets
            /*.filter(bucket => {
            return !bucket.is_owner && bucket.shared
          })*/
            .sort(compareFunction)
        )
      } else if (this.filterByType === 'unit_edition') {
        return buckets
          .filter(bucket => {
            return (
              (bucket.type === 'unit_edition' || bucket.type === 'unit') &&
              bucket.context &&
              bucket.context.academic_year === this.selectedAcademicYear &&
              ((bucket.context.regime === 'semiannual' && bucket.context.regime_occurrence === this.selectedSemester) ||
                bucket.context.regime !== 'semiannual')
            )
          })
          .sort(function(a, b) {
            if (a['context'] && a['context']['unit_title'] && b['context'] && b['context']['unit_title']) {
              let result = a['context']['unit_title'].localeCompare(b['context']['unit_title'])
              if (result === 0) {
                if (a.type === 'unit' && b.type !== 'unit') {
                  return -1
                }
                if (b.type === 'unit' && a.type !== 'unit') {
                  return 1
                }
              } else {
                return result
              }
            }
          })
      } else if (this.filterByType === 'class_edition') {
        return buckets
          .filter(bucket => {
            return (
              bucket.type === 'class_edition' &&
              bucket.context &&
              bucket.context.academic_year === this.selectedAcademicYear &&
              ((bucket.context.regime === 'semiannual' && bucket.context.regime_occurrence === this.selectedSemester) ||
                bucket.context.regime !== 'semiannual')
            )
          })
          .sort(function(a, b) {
            return a['title'].localeCompare(b['title'])
          })
      }
      /*} else if (this.academicBucketTypes.includes(this.filterByType)) {
        return buckets.filter(bucket => {
          return (
            bucket.type === this.filterByType &&
            bucket.context &&
            bucket.context.academic_year === this.academicBucketsByYear[this.filterByType].selectedYear
          )
        })
      }*/
      return buckets
        .filter(bucket => {
          return bucket.type === this.filterByType
        })
        .sort(function(a, b) {
          return a['title'].localeCompare(b['title'])
        })
    },
    unitsListFiltered() {
      return this.unitsList.filter(bucket => {
        return (
          (bucket.regime === 'semiannual' && bucket.regime_occurrence === this.selectedSemester) ||
          bucket.regime !== 'semiannual'
        )
      })
    },
    createAllowed() {
      // TODO: We need to organize permissions in a better way
      return this.user?.meeting
    },
  },

  watch: {
    $route() {
      console.log('route changed')
      this.processRouteChange()
    },
    bucketsFiltered(newvalue) {
      if (!this.isModal && this.loadedUserViewPreferences === false && newvalue.length >= 10) {
        this.list_view = 'row'
      }
    },
    unitsListFiltered(newvalue) {
      if (!this.isModal && this.loadedUserViewPreferences === false && newvalue.length >= 10) {
        this.list_view = 'row'
      }
    },
  },

  async created() {
    if (this.$route.params.filter) {
      console.log('Applied url filter')
      let filter = rehidratedFiltersMap[this.$route.params.filter]
      if (typeof filter !== 'undefined') {
        this.filterByType = filter
      } else {
        this.filterByType = 'user'
      }
    }

    if (this.$route.query.semester && parseInt(this.$route.query.semester) !== 'NaN') {
      this.selectedSemester = parseInt(this.$route.query.semester)
    }

    if (this.$route.query.academic_year) {
      this.selectedAcademicYear = this.$route.query.academic_year
    }

    if (this.list && this.isModal) {
      this.list_view = 'row_expanded'
    } else if (this.list) {
      this.list_view = 'row'
    } else {
      //restore user settings
      console.log('Restore user settings')
      await this.restoreUserViewSettings()
    }
    this.processRouteChange()
  },
  /*mounted() {
    if (this.autoScroll) {
      /*console.log(
          this.$parent.$el.getElementsByTagName('main')[0].offsetHeight,
          this.$parent.$el.getElementsByTagName('main')[0].offsetTop
        )
        console.log(this.$refs.listHeader)
        //console.log(this.$refs)
        console.log(this.$refs.listHeader.offsetHeight, this.$refs.listHeader.offsetTop)
        //

        */
  /*let self = this
      Vue.nextTick(function() {
        // DOM updated
        //let footerHeigth = window.innerWidth < 1024 ? 53.5 : 0

        let header = document.querySelector('header')
        let footer = document.querySelector('#tapbar-slot')
        let footerHeight = footer ? footer.offsetHeight : 0
        let headerHeigth = header.offsetHeight

        //console.log('windows height', window.innerHeight)
        //console.log('MAIN WINDOW: ', window.innerHeight - (footerHeight + headerHeigth))
        //console.log('MAIN HEGHT', this.$parent.$el.getElementsByTagName('main')[0].offsetHeight)
        //console.log(header)
        //console.log(footer)
        //console.log(document.getElementsByTagName('main')[0])

        /*this.autoParentHeight =
            this.$parent.$el.getElementsByTagName('main')[0].offsetHeight - 52 - 28 - 60 - 50 - footerHeigth*/
  //self.autoParentHeight = window.innerHeight - (footerHeight + headerHeigth) - 52 - 28 - 36
  //this.$parent.$el.getElementsByTagName('main')
  //console.log('MAX: ', self.autoParentHeight)
  /*})*/
  //this.$refs.listHeader.offsetTop
  /*  }
  },*/
  methods: {
    async restoreUserViewSettings() {
      let data = await ServiceSettings.getSetting(`general-view-settings`)
      if (data && data !== null) {
        let viewSettings = JSON.parse(data.value)
        //console.log(bucketSettings)
        //{"view_type":"row","view_order_type":"created_date","view_order_direction":"DESC"}
        if (viewSettings && viewSettings['view_type']) {
          const viewType = viewSettings['view_type']
          if (viewType && ['row', 'card'].includes(viewType)) {
            this.loadedUserViewPreferences = true
            this.list_view = viewType
            ///this.createFilters()
          }
        }
      }
    },
    setView(viewType) {
      //save in thge user settings
      this.list_view = viewType
      this.createFilters()
      this.saveViewSettings()
      console.log('view change', viewType)
    },
    async saveViewSettings() {
      let settings = {
        view_type: this.list_view,
      }
      await ServiceSettings.setSetting(`general-view-settings`, JSON.stringify(settings))
    },
    async processRouteChange() {
      let currentSemester = 1
      if (this.$route.query.semester && parseInt(this.$route.query.semester) !== 'NaN') {
        currentSemester = parseInt(this.$route.query.semester)
      } else {
        //Auto select semester
        let now = new Date()
        let currentYear = now.getFullYear()
        let semesterStartDates = config.semesterStart

        for (let i = 0; i < semesterStartDates.length; i++) {
          let start = Date.parse(currentYear + '-' + semesterStartDates[i])
          //console.log('start: ', currentYear + '-' + semesterStartDates[i], start)
          if (now.getTime() >= start) {
            currentSemester = i === 0 ? 1 : 2 //2 : 1
            break
          }
        }
      }

      this.selectedSemester = currentSemester

      //create default filters
      this.createFilters()
      let academic_year = null
      let filter = null
      if (this.$route.params.filter) {
        filter = this.$route.params.filter
        if (this.$route.query.academic_year) {
          academic_year = this.$route.query.academic_year
        }
      }
      if (filter) {
        this.changeFilter(filter, academic_year, null, true)
      }

      if (
        (this.currentAppName === 'ucstudent' || this.currentAppName === 'ucteacher') &&
        (this.filterByType === 'classes_and_units' || this.academicBucketTypes.includes(this.filterByType))
      ) {
        await this.loadClasses()
      }
      await this.loadBuckets()
      if (this.filterByType === 'classes_and_units') {
        this.divideBucketsByType()
        await this.groupUnitsAndClasses()
      }
    },
    createFilters() {
      if (this.overrideWithClassesList) {
        if (this.isTeacher && (this.currentAppName === 'ucstudent' || this.currentAppName === 'ucteacher')) {
          this.filters = [
            {
              key: '',
              label: 'Todos',
            },
            {
              key: 'classes_and_units',
              label: 'Disciplinas & Turmas',
            },
            {
              key: 'user',
              label: 'Pessoais',
            },
            {
              key: 'groups',
              label: 'Grupos',
            },
          ]
          this.filterByType = 'classes_and_units'
        } else {
          this.filters = [
            {
              key: '',
              label: 'Todos',
            },
            {
              key: 'user',
              label: 'Pessoais',
            },
            {
              key: 'groups',
              label: 'Grupos',
            },
          ]
          this.filterByType = 'user'
        }
      } else if (this.joinUnitsAndClasses) {
        console.log('joinUnitsAndClasses', this.joinUnitsAndClasses)
        if (this.list_view === 'row') {
          let filters = [
            {
              key: '',
              label: 'Todos',
            },
            {
              key: 'user',
              label: 'Pessoais',
            },
            {
              key: 'shared', //TODO: Change to shared_with_me
              label: 'Partilhados comigo',
            },
            {
              key: 'groups',
              label: 'Grupos',
            },
          ]

          if (this.currentAppName === 'ucstudent' || this.currentAppName === 'ucteacher') {
            filters.push({
              key: 'classes_and_units',
              label: 'Disciplinas & Turmas',
              new_label: true,
            })
          }

          this.filters = filters

          if (
            this.filterByType === 'units' ||
            this.filterByType === 'unit_edition' ||
            this.filterByType === 'class_edition'
          ) {
            //this.filterByType = 'classes_and_units'
            this.changeFilter('classes_and_units', this.selectedAcademicYear, this.selectedSemester, false)
            //console.log('changing filter to ', this.filterByType)
          }
        } else {
          let filters = [
            {
              key: '',
              label: 'Todos',
            },
            {
              key: 'user',
              label: 'Pessoais',
            },
            {
              key: 'shared', //TODO: Change to shared_with_me
              label: 'Partilhados comigo',
            },
            {
              key: 'groups',
              label: 'Grupos',
            },
          ]

          if (this.currentAppName === 'ucstudent' || this.currentAppName === 'ucteacher') {
            filters.push({
              key: 'unit_edition',
              label: 'Disciplinas',
            })
            filters.push({
              key: 'class_edition',
              label: 'Turmas',
            })
          }

          this.filters = filters

          if (this.filterByType === 'classes_and_units') {
            //this.filterByType = 'unit_edition'
            this.changeFilter('unit_edition', this.selectedAcademicYear, this.selectedSemester, false)
            //console.log('changing filter to ', this.filterByType)
          }
        }
      }
    },
    changeFilter(filter, academic_year = null, semester = null, fromRouteChange = false) {
      if (fromRouteChange) {
        filter = rehidratedFiltersMap[filter]
        if (typeof filter !== 'undefined') {
          this.filterByType = filter
        } else {
          this.filterByType = 'user'
        }
        this.setAcademicYear(academic_year, false, false)
      }
      let currentFilter = this.filterByType
      this.filterByType = filter

      if (semester === null) {
        semester = this.selectedSemester
      }

      if (currentFilter !== filter) {
        //set default academic year if we change the type of bucket
        this.setAcademicYear(null, false, false)
      }
      if (this.filterWithRoutes && fromRouteChange === false) {
        let url = '/buckets'
        let filtersMap = {
          unit_edition: '/units',
          class_edition: '/classes',
          groups: '/groups',
          shared: '/shared',
          shared_with_me: '/shared_with_me',
          classes_and_units: '/classes_and_units',
          user: '/user',
        }
        if (filtersMap[filter]) {
          url += filtersMap[filter]
        }
        let query = null
        if (academic_year !== null) {
          query = {
            academic_year: academic_year,
            semester: semester,
          }
        }
        if (!(JSON.stringify(query) === JSON.stringify(this.$route.query) && this.$route.path === url)) {
          this.$router.push({ path: url, query: query })
        }
      }
    },
    emitSelected() {
      if (this.filterByType === 'classes_and_units') {
        this.$emit('selected', Array.from(this.classBucketsSelected))
      } else {
        let result = []
        let buckets = this.buckets
        for (let i = 0; i < buckets.length; i++) {
          if (buckets[i].selected) {
            result.push(buckets[i].key)
          }
        }
        this.$emit('selected', result)
      }
    },
    emitSelectedClassBucket(raw_key, value, new_repo, repo_type, repo_typeid, repo_name) {
      let key = raw_key
      if (new_repo === true && repo_type === 'class_edition') {
        key = 'new_repo;' + repo_type + ';' + repo_typeid + ';' + repo_name
      }
      if (value) {
        this.classBucketsSelected.add(key)
      } else {
        this.classBucketsSelected.delete(key)
      }
      this.emitSelected()
    },
    async loadBuckets() {
      this.loading = true
      try {
        console.log(this.applicationContext, this.permissions)

        console.log('using new bucket filters', this.filterByType)
        let filter = [this.filterByType]
        let filterCache = this.filterByType
        let academic_year = this.selectedAcademicYear
        if (this.filterByType === 'classes_and_units') {
          filter = ['unit', 'unit_edition', 'class_edition']
          filterCache = 'classes_and_units_' + academic_year
        } else if (this.filterByType === 'unit_edition') {
          filter = ['unit', 'unit_edition']
          filterCache = 'unit_edition_' + academic_year
        } else if (this.filterByType === 'class_edition') {
          filter = ['class_edition']
          filterCache = 'class_edition_' + academic_year
        } else {
          academic_year = null
        }

        if (typeof this.bucketsCache[filterCache] === 'undefined') {
          this.bucketsCache[filterCache] = []
        }

        if (this.bucketsCache[filterCache].length > 0) {
          console.log('loading buckets from cache')
        } else {
          if (this.applicationContext || (this.permissions && this.permissions.length > 0)) {
            console.log('this.applicationContext', this.applicationContext)
            console.log('this.permissions', this.permissions)
            console.log('loading buckets from network with context')
            this.bucketsCache[filterCache] = await ServiceBuckets.getBuckets(
              this.applicationContext,
              null,
              this.permissions
            )
          } else {
            this.bucketsCache[filterCache] = await ServiceBuckets.getBucketsWithFilters(filter, academic_year)
          }
          console.log('loading buckets from network')
        }
        this.buckets = this.bucketsCache[filterCache]
        //this.$forceUpdate()

        /*if (this.overrideWithClassesList || (this.joinUnitsAndClasses && this.filterByType === 'classes_and_units')) {
          //load classes list
          this.loadClasses(true)
        } else {
          this.breakAcademicBucketsByYear(setAcademicYear)
          this.loading = false
        }*/
      } finally {
        this.loading = false
      }
    },
    divideBucketsByType() {
      let bucketsDicio = {}
      let buckets = this.buckets
      for (let i = 0; i < buckets.length; i++) {
        if (buckets[i].type === 'unit_edition' || buckets[i].type === 'class_edition' || buckets[i].type === 'unit') {
          if (bucketsDicio[buckets[i]['type_key']]) {
            bucketsDicio[buckets[i]['type_key']].push(buckets[i])
          } else {
            bucketsDicio[buckets[i]['type_key']] = [buckets[i]]
          }
        }
      }
      this.bucketsByClassDicio = bucketsDicio
    },
    groupUnitsAndClasses() {
      let unitList = this.unitsList
      if (
        this.groupedDataCache[this.selectedAcademicYear] &&
        this.groupedDataCache[this.selectedAcademicYear].length > 0
      ) {
        this.unitsList = this.groupedDataCache[this.selectedAcademicYear]
      } else {
        //Esta funçao necessita dos buckets divididos por tipos
        //RESET PROCESSED
        for (let i = 0; i < unitList.length; i++) {
          unitList[i].processed = false
        }

        let results = []
        if ((this.joinUnitsAndClasses || this.overrideWithClassesList) && unitList.length > 0) {
          for (let i = 0; i < unitList.length; i++) {
            let unitData = unitList[i]
            if (unitData.processed !== true) {
              let newData = {
                title: unitData.title,
                regime: unitData.regime,
                regime_occurrence: unitData.regime_occurrence,
                unit_buckets: [],
                classes: unitData.classes,
                courses: unitData.courses.map(c => c.title),
              }

              if (unitData.unit_key_for_bucket && unitData.unit_key_for_bucket !== null) {
                //add general unit buckets
                if (typeof this.bucketsByClassDicio[unitData.unit_key_for_bucket] !== 'undefined') {
                  newData.unit_buckets = this.bucketsByClassDicio[unitData.unit_key_for_bucket]
                }
                //add unit buckets
                if (typeof this.bucketsByClassDicio[unitData.key] !== 'undefined') {
                  newData.unit_buckets = newData.unit_buckets.concat(this.bucketsByClassDicio[unitData.key])
                }

                //verify if we have other related units
                for (let p = i + 1; p < unitList.length; p++) {
                  if (unitData.unit_key_for_bucket === unitList[p].unit_key_for_bucket) {
                    //found related unit
                    let relatedUnitData = unitList[p]
                    //add unit buckets
                    if (typeof this.bucketsByClassDicio[relatedUnitData.key] !== 'undefined') {
                      newData.unit_buckets = newData.unit_buckets.concat(this.bucketsByClassDicio[relatedUnitData.key])
                    }

                    // Add courses
                    for (let j = 0; j < relatedUnitData.courses.length; j++) {
                      let courseData = relatedUnitData.courses[j]
                      if (!newData.courses.includes(courseData.title)) {
                        newData.courses.push(courseData.title)
                      }
                    }

                    //add classes
                    newData.classes = newData.classes.concat(relatedUnitData.classes)

                    //mark as processed
                    unitList[p]['processed'] = true
                  }
                }
              }

              newData.classes.sort(function(a, b) {
                return a['title'].localeCompare(b['title'])
              })

              //add data to results
              results.push(newData)
            }
          }
        }
        this.groupedDataCache[this.selectedAcademicYear] = results
      }
      this.unitsList = this.groupedDataCache[this.selectedAcademicYear]
    },
    async loadClasses() {
      this.loading = true
      try {
        //if (this.list_view !== 'row') {
        //Buckets dicio
        //if (firstload) {
        //Pois podem surgir mais buckets
        //this.divideBucketsByType()
        //}

        console.log('loading classes')
        let academicYear =
          this.selectedAcademicYear === -1 || this.selectedAcademicYear === '-1' ? null : this.selectedAcademicYear
        if (this.unitsListCache[academicYear]) {
          //use data in cache
          this.unitsList = this.unitsListCache[academicYear] //this.academicBucketsByYear['classes_and_units'].years[this.selectedAcademicYear]
          console.log('units from cache', this.unitsList)
        } else {
          //load classes list
          let resultUnits = []
          if (this.isTeacher) {
            resultUnits = await ServiceAcademic.getTeacherUnits(academicYear, true)
          } else {
            resultUnits = await ServiceAcademic.getStudentUnits(academicYear, true)
          }
          if (academicYear === null) {
            academicYear = resultUnits.academic_year
            this.selectedAcademicYear = academicYear
          }
          this.academicYearOptions = resultUnits.academic_years
          this.unitsListCache[academicYear] = resultUnits.units
          this.unitsList = resultUnits.units
        }

        //console.log('units from network', this.unitsList)
        /*if (firstload) {
            this.academicBucketsByYear['classes_and_units'].selectedYear = resultUnits.academic_year
          }*/
        //Create cache
        /*if (firstload) {
            for (let i = 0; i < resultUnits.academic_years.length; i++) {
              if (resultUnits.academic_years[i] === resultUnits.academic_year) {
                this.academicBucketsByYear['classes_and_units'].years[resultUnits.academic_years[i]] = results
              } else {
                this.academicBucketsByYear['classes_and_units'].years[resultUnits.academic_years[i]] = []
              }
            }
          } else {
            this.academicBucketsByYear['classes_and_units'].years[this.selectedAcademicYear] = resultUnits.units
          }
          //this.academicBucketsByYear['classes_and_units'].years = teacherUnits.academic_years
        }*/

        //} else {
        //NEW CACHE SYSTEM

        //}
      } finally {
        this.loading = false
      }
    },
    breakAcademicBucketsByYear(setDefaultYear = true) {
      for (let i = 0; i < this.buckets.length; i++) {
        let bucket = this.buckets[i]
        if (this.academicBucketTypes.includes(bucket.type) && bucket.context) {
          let destin = bucket.type === 'unit' ? 'unit_edition' : bucket.type
          if (this.academicBucketsByYear[destin].years.hasOwnProperty(bucket.context.academic_year)) {
            this.academicBucketsByYear[destin].years[bucket.context.academic_year].push(bucket)
          } else {
            this.academicBucketsByYear[destin].years[bucket.context.academic_year] = [bucket]
          }
        }
      }

      // Set default academic year
      this.academicBucketTypes.forEach(type => {
        if (type !== 'unit') {
          let years = Object.keys(this.academicBucketsByYear[type].years)
          if (years.length) {
            // Sort and default selected year
            years.sort().reverse()
            this.academicBucketsByYear[type].selectedYear = years[0]
            if (setDefaultYear) {
              this.selectedAcademicYear = years[0]
            }
          }
        }
      })
    },
    setSemester(semester) {
      //console.log('setSemester')
      let year = this.selectedAcademicYear
      this.selectedSemester = semester
      this.changeFilter(this.filterByType, year, semester)
    },
    async setAcademicYear(year, toogle_dropbox = true, changeRoute = false) {
      //console.log('setAcademicYear', year, toogle_dropbox, changeRoute)
      if (
        year === null &&
        this.academicBucketsByYear[this.filterByType] &&
        this.academicBucketsByYear[this.filterByType].selectedYear
      ) {
        this.selectedAcademicYear = this.academicBucketsByYear[this.filterByType].selectedYear
      } else if (year !== null) {
        this.selectedAcademicYear = year
      }
      //this.academicBucketsByYear[this.filterByType].selectedYear = year
      if (toogle_dropbox) {
        /*if (typeof this.$refs.academicYearsDropdown !== 'undefined') {
          this.$refs.academicYearsDropdown.toggle()
        }*/
        if (changeRoute) {
          this.changeFilter(this.filterByType, year, this.selectedSemester)
        }
      }
    },
    getClassBuckets(classID, className, isUnit, unitKey = null) {
      let class_buckets = []
      if (unitKey !== null && typeof this.bucketsByClassDicio[unitKey] !== 'undefined') {
        class_buckets = class_buckets.concat(this.bucketsByClassDicio[unitKey])
        //console.log('FOUND UNIT BUCKETS', class_buckets)
      }
      if (typeof this.bucketsByClassDicio[classID] !== 'undefined' && this.bucketsByClassDicio[classID].length > 0) {
        let add = this.bucketsByClassDicio[classID]
        class_buckets = class_buckets.concat(add)
      }
      //console.log('UNIT KEY', unitKey)
      return typeof class_buckets !== 'undefined' && class_buckets.length > 0
        ? class_buckets
        : isUnit || this.joinUnitsAndClasses
        ? []
        : [
            {
              context: null,
              created_date: null,
              is_owner: true,
              new_repo: true,
              key: 'Criar repo',
              shared: true,
              stats: null,
              title: 'Repositório da ' + className,
              type: 'class_edition',
              type_key: classID,
              updated_date: null,
              updated_date_obj: null,
            },
          ]
    },
    //Units + classes methods
    /*selectAcademicYear(year) {
        this.$refs.academicYearsDropdownUnitClasses.toggle()
        this.selectedAcademicYear = year
        this.loadClasses()
      },*/
  },
}
</script>
<style scoped>
.autoScrollActive {
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.darkScrollArea {
  background-color: #efefef;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
