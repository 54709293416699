<template>
  <div class="flex flex-col h-full">
    <div class="flex gap-5 items-center" :class="{ 'px-5 p-5 lg:px-8 lg:pt-8 lg:pb-7': !paddingless }">
      <fw-avatar :size="'lg'" :user="user"></fw-avatar>
      <div class="flex-1 my-2">
        <div class="flex gap-2">
          <fw-heading size="h2" class="flex-1">
            {{ user.display_name || user.full_name }}
          </fw-heading>
          <div v-if="user.type" class="flex gap-2 items-center">
            <div
              class="uppercase font-bold text-xs leading-5 py-1.5 px-3 rounded-xl text-gray-500 border border-gray-500"
            >
              {{
                user.type === USER_UC_TYPE
                  ? $t('ucAccount')
                  : user.type === USER_EXTERNAL_TYPE
                  ? $t('externalAccount')
                  : ''
              }}
            </div>
          </div>
        </div>
        <div class="md:flex gap-5">
          <div class="font-medium break-all max-w-lg">{{ user.email }}</div>
          <div class="font-medium text-sm flex gap-5 text-gray-500 flex-shrink-0 leading-6">
            <div v-if="user.type === USER_UC_TYPE && user.number" class="flex-shrink-0">Nº {{ user.number }}</div>
            <div class="flex-shrink-0">
              <a
                v-if="user.meeting"
                :href="getUserMeetingRoomUrl()"
                class="flex items-center gap-1"
                title="Sala na UC Meetings"
                target="_black"
              >
                <fw-icon-live class="w-5 h-5" />
                {{ user.meeting.key_alias || user.meeting.key }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!onlyHeader" class="h-full overflow-auto px-5 lg:px-8">
      <div>
        <fw-panel :title="$t('metadata')" class="mb-3" custom-class="bg-gray-100 flex flex-col gap-3" boxed="sm">
          <div class="flex flex-col gap-3">
            <div>
              <fw-label marginless>{{ $t('fullName') }}</fw-label>
              <div>{{ user.full_name }}</div>
            </div>
            <div>
              <fw-label marginless>{{ $t('displayName') }}</fw-label>
              <div v-if="user.display_name">{{ user.display_name }}</div>
              <div v-else class="text-sm text-gray-500">{{ $t('notDefined') }}</div>
            </div>
            <div v-if="user.type === USER_UC_TYPE">
              <fw-label marginless>{{ $t('institutionalEmails') }}</fw-label>
              <div class="break-all">{{ user.emails.join(', ') }}</div>
            </div>
            <div v-if="user.type === USER_UC_TYPE">
              <fw-label marginless>{{ $t('number') }}</fw-label>
              <div :class="{ 'text-sm text-gray-500': !user.number }">{{ user.number || 'N/A' }}</div>
            </div>
            <div v-if="user.type">
              <fw-label marginless>{{ $t('accountType') }}</fw-label>
              <div>
                {{
                  user.type === USER_UC_TYPE
                    ? $t('institutionalAccount')
                    : user.type === USER_EXTERNAL_TYPE
                    ? $t('externalAccount')
                    : $t('invite')
                }}
              </div>
            </div>
            <div>
              <fw-label marginless>{{ $t('phone') }}</fw-label>
              <div :class="{ 'text-sm text-gray-500': !user.phone }">{{ user.phone || $t('notDefined') }}</div>
            </div>
            <div v-if="debugmode">
              <fw-label marginless>{{ $t('settings') }}</fw-label>
              <div :class="{ 'text-sm text-gray-500': !user.options }">{{ user.options || $t('notDefined') }}</div>
            </div>
          </div>
          <div>
            <slot name="more-meta"></slot>
          </div>
        </fw-panel>
        <slot></slot>
        <fw-panel-info debug label="User data (raw)" class="my-3">
          <json-viewer :value="user"></json-viewer>
        </fw-panel-info>
      </div>
    </div>
  </div>
</template>

<script>
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import FwAvatar from '@/fw-modules/fw-core-vue/ui/components/FwAvatar'

export default {
  name: 'BlockUserDetails',
  components: { FwAvatar },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: function() {
        return {}
      },
    },
    onlyHeader: {
      type: Boolean,
      default: false,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      USER_UC_TYPE: 'uc',
      USER_EXTERNAL_TYPE: 'external',
    }
  },
  computed: {
    debugmode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
  },
  methods: {
    getUserMeetingRoomUrl() {
      if (this.user.meeting) {
        return `${FwEnvConfig.ucmeetingsUrl}/live/${this.user.meeting.meeting_alias || this.user.meeting.key}`
      }
      return
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "notDefined": "Não definido.",
    "externalAccount": "Conta Externa",
    "invite": "Convite",
    "institutionalAccount": "Conta institucional",
    "ucAccount": "Conta UC",
    "fullName": "Nome completo",
    "displayName":"Nome de apresentação",
    "institutionalEmails": "Endereços de email institucionais",
    "number":"Número",
    "accountType": "Tipo de conta",
    "phone": "Telefone",
    "settings": "Definições",
    "metadata": "Metadados"
  },
  "en": {
    "notDefined": "Not defined.",
    "externalAccount": "External account",
    "invite": "Invite",
    "institutionalAccount": "Institutional account",
    "ucAccount": "UC account",
    "fullName": "Full name",
    "displayName":"Display name",
    "institutionalEmails": "Institutional emails",
    "number":"Number",
    "accountType": "Account type",
    "phone": "Phone number",
    "settings": "Settings",
    "metadata": "Metadata"
  }
}
</i18n>
