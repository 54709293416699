<template>
  <div class="h-full">
    <RecycleScroller
      v-if="notifications.length"
      v-slot="{ item }"
      :items="notifications"
      :item-size="95"
      :buffer="25"
      key-field="key"
    >
      <div
        class="rounded-lg overflow-hidden group relative px-4 py-2 flex gap-2 border-b mb-1 min-h-26 bg-white"
        :class="{
          'shadow-none hover:shadow': selected != item.key,
          'shadow bg-primary bg-opacity-10': selected == item.key,
        }"
      >
        <div v-if="type === 'full'" class="w-12" @click="goToNotification(item.key, false)">
          <div
            class="w-10 h-10 overflow-hidden rounded-lg cursor-pointer flex items-center justify-center object-cover group-hover:opacity-70"
          >
            <svg
              v-if="getAppIcon(item) == 'default'"
              class="fill-current w-10 h-10 p-2 lg:p-3 bg-gray-900 text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M18 10a6 6 0 1 0-12 0v8h12v-8zm2 8.667l.4.533a.5.5 0 0 1-.4.8H4a.5.5 0 0 1-.4-.8l.4-.533V10a8 8 0 1 1 16 0v8.667zM9.5 21h5a2.5 2.5 0 1 1-5 0z"
              />
            </svg>
            <img v-else :src="getAppIcon(item)" />
          </div>
        </div>
        <div class="flex-1 flex flex-col gap-1" :class="{ 'ml-4': type === 'minimal' }">
          <div class="flex items-center justify-between text-gray-500 gap-1" @click="goToNotification(item.key, false)">
            <div class="font-medium text-xs uppercase group-hover:text-opacity-30">{{ getAppName(item) }}</div>
            <div class="flex gap-3">
              <div class="text-xs text-gray-500">
                {{ item.created_date | humanDateTimePT }}
              </div>
              <div class="flex items-center">
                <fw-dot
                  inline
                  :color="item.unread ? 'primary' : 'light'"
                  :ping="item.unread"
                  :class="{ 'opacity-60': !item.unread }"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-1" @click="goToNotification(item.key, false)">
            <v-clamp
              v-if="item.title !== item.message"
              :class="{
                'font-bold': item.unread,
                'font-normal': !item.unread,
                'cursor-pointer': activeClick,
              }"
              class="text-base text-gray-800 cursor-pointer text-left leading-5 group-hover:text-opacity-50"
              autoresize
              :max-lines="1"
              >{{ item.title }}</v-clamp
            >
            <v-clamp
              :class="{
                'font-bold': item.unread && item.title === item.message,
                'text-sm text-opacity-70 group-hover:text-opacity-30': item.title !== item.message,
                'text-base': item.title === item.message,
                'cursor-pointer': activeClick,
              }"
              class="text-gray-800 text-left min-h-11"
              autoresize
              :max-lines="2"
              >{{ item.message }}</v-clamp
            >
          </div>
          <div
            v-if="showOptions"
            class="flex pointer-events-none items-end justify-end px-3 pb-1 pt-5 group-hover:opacity-90 opacity-0 absolute left-0 bottom-0 right-0 bg-gradient-to-t from-white to-transparent"
          >
            <div
              v-if="item.unread && item.type != 'acceptance'"
              class="px-4 py-1 rounded-sm inline-flex pointer-events-auto"
            >
              <fw-button
                size="sm"
                type="link"
                paddingless
                class="flex items-center gap-1"
                @click.native="update(item, 'read')"
              >
                <fw-icon-check class="w-5 h-5" /> {{ $t('markAsRead') }}
              </fw-button>
            </div>
            <div
              v-if="view != 'archived' && !item.unread && item.type != 'acceptance'"
              class="px-4 py-1 rounded-sm inline-flex pointer-events-auto"
            >
              <fw-button
                size="sm"
                type="link"
                paddingless
                class="flex items-center gap-1"
                @click.native="update(item, 'unread')"
              >
                <fw-icon-check class="w-5 h-5" />
                {{ $t('markAsUnread') }}
              </fw-button>
            </div>
            <div
              v-if="
                view != 'unread' && view != 'archived' && type === 'full' && (!item.unread || item.type != 'acceptance')
              "
              class="px-4 py-1 rounded-sm inline-flex pointer-events-auto"
            >
              <fw-button
                size="sm"
                type="link-muted"
                paddingless
                class="flex items-center gap-1"
                @click.native="update(item, 'archive')"
              >
                <fw-icon-archive class="w-4 h-4" /> {{ $t('archive') }}
              </fw-button>
            </div>
            <div v-if="view == 'archived' && type === 'full'" class="px-3 rounded-sm inline-flex pointer-events-auto">
              <fw-button
                size="sm"
                type="link"
                paddingless
                class="flex items-center gap-1"
                @click.native="update(item, 'unarchive')"
              >
                <fw-icon-unarchive class="w-4 h-4" />
                {{ $t('moveToInbox') }}
              </fw-button>
            </div>
            <div v-if="item.unread && item.type == 'acceptance'" class="font-bold text-sm px-2 pointer-events-auto">
              {{ $t('awaitsAcceptance') }}
            </div>
            <div class="px-4 py-1 rounded-sm inline-flex pointer-events-auto">
              <fw-button
                size="sm"
                type="link"
                paddingless
                class="flex items-center gap-1"
                @click.native="seeNotification(item.key)"
              >
                {{ $t('see') }}
              </fw-button>
            </div>
            <div v-if="!hideOpen && item.with_redirect" class="px-4 py-1 rounded-sm inline-flex pointer-events-auto">
              <fw-button
                size="sm"
                type="link"
                paddingless
                class="flex items-center gap-1"
                @click.native="goToNotification(item.key, true)"
              >
                {{ $t('open') }} <fw-icon-arrow-right class="w-5 h-5 flex-shrink-0 inline-flex" />
              </fw-button>
            </div>
          </div>
        </div>
      </div>
    </RecycleScroller>

    <div v-else class="flex flex-col items-center justify-center gap-2 py-10 h-full text-gray-500 text-sm">
      <div>{{ $t('noNotifications') }}</div>
    </div>

    <b-modal
      v-if="activeNotification"
      :active="isDetailsModalOpen"
      :width="500"
      scroll="keep"
      trap-focus
      aria-role="dialog"
      aria-modal
      :can-cancel="true"
      has-modal-card
      custom-class="z-100"
      @close="closeDetailsModal"
    >
      <div class="modal-card" style="width: auto; min-width: 360px">
        <div class="modal-card-body">
          <div class="px-1 flex gap-2 items-center text-sm">
            <div>
              <svg
                v-if="getAppIcon(activeNotification) == 'default'"
                class="fill-current w-12 h-12 md:w-14 md:h-14 rounded-lg p-2 lg:p-4 bg-gray-900 text-white shadow-md"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M18 10a6 6 0 1 0-12 0v8h12v-8zm2 8.667l.4.533a.5.5 0 0 1-.4.8H4a.5.5 0 0 1-.4-.8l.4-.533V10a8 8 0 1 1 16 0v8.667zM9.5 21h5a2.5 2.5 0 1 1-5 0z"
                />
              </svg>
              <div
                v-else
                class="w-12 h-12 md:w-14 md:h-14 overflow-hidden rounded-lg flex items-center justify-center object-cover shadow-md"
              >
                <img :src="getAppIcon(activeNotification)" />
              </div>
            </div>
            <div class="flex-1">
              <fw-label marginless>{{ $t('notification') }}</fw-label>
              <div class="font-bold uppercase">{{ getAppName(activeNotification) }}</div>
            </div>
            <div class="text-gray-500">{{ activeNotification.created_date | formatDateTime }}</div>
          </div>
          <div
            class="mt-7 mb-1 text-gray-800 font-bold text-center p-5 bg-gray-100 rounded-lg"
            v-html="activeNotification.message"
          />

          <div v-if="activeNotification.type == 'acceptance' && !activeNotification.date" class="mt-2">
            <fw-button
              type="primary"
              size="md"
              expanded
              class="flex items-center justify-center gap-1"
              @click.native="update(activeNotification, 'accept')"
            >
              {{ $t('accept') }}
            </fw-button>
          </div>
          <div v-else class="flex w-full items-center gap-2 justify-center mb-7">
            <fw-button
              v-if="!activeNotification.date"
              type="link-muted"
              class="flex items-center gap-1"
              @click.native="update(activeNotification, 'read')"
            >
              <fw-icon-check class="w-5 h-5" />
              {{ $t('markAsRead') }}
            </fw-button>
            <fw-button
              v-if="!activeNotification.archived_date"
              type="link-muted"
              class="flex items-center gap-1"
              @click.native="update(activeNotification, 'archive')"
            >
              <fw-icon-archive class="w-4 h-4" />
              {{ $t('archive') }}
            </fw-button>
            <fw-button
              v-if="activeNotification.archived_date"
              type="link-muted"
              class="flex items-center gap-1"
              @click.native="update(activeNotification, 'unarchive')"
            >
              <fw-icon-unarchive class="w-4 h-4" />
              {{ $t('moveToInbox') }}
            </fw-button>
          </div>

          <div class="flex justify-center items-center mt-2">
            <fw-button
              :type="!activeNotification.date && activeNotification.type == 'acceptance' ? 'link-muted' : 'black'"
              :expanded="true"
              @click.native="closeDetailsModal"
              >{{ $t('close') }}</fw-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ServiceNotifications from '@/fw-modules/fw-core-vue/notifications/services/ServiceNotifications'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import config from '@/fw-modules/fw-core-vue/config.js'
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  components: {
    RecycleScroller,
  },

  props: {
    view: String,
    notifications: Array,
    type: {
      type: String,
      default: 'full',
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
    query: {
      type: String,
      default: '',
    },
    selected: {
      type: String,
      default: '',
    },
    hideOpen: {
      type: Boolean,
      default: false,
    },
    forceOpenDetails: {
      type: Boolean,
      default: true,
    },
    activeClick: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isDetailsModalOpen: false,
      activeNotification: null,
    }
  },

  computed: {
    apps() {
      return this.$store.getters.getApps
    },
  },

  methods: {
    hightlight(text, query) {
      if (query && query.length > 0) {
        return text.replace(
          new RegExp(`\\b\\w*${query}\\w*\\b`, 'gi'),
          match => `<span class="bg-primary bg-opacity-40 rounded-md px-1">${match}</span>`
        )
      }
      return text
    },
    closeDetailsModal() {
      if (this.activeNotification && this.view == 'unread') {
        this.$store.dispatch('removeUnreadNotification', this.activeNotification.key)
      }

      this.activeNotification = null
      this.isDetailsModalOpen = false
    },

    seeNotification(key) {
      this.$router.push({ name: 'notification', params: { key: key } })
    },
    async goToNotification(key) {
      if (this.forceOpenDetails) {
        this.seeNotification(key)
        return
      }

      this.activeNotification = await ServiceNotifications.get(key, this.view == 'unread')
      //console.log(this.activeNotification)

      if (
        this.activeNotification.redirect_url !== null &&
        typeof this.activeNotification.redirect_url === 'string' &&
        this.activeNotification.redirect_url.length > 0
      ) {
        const redirectUrl = this.activeNotification.redirect_url
        let hostname_withoutprotocol = redirectUrl.startsWith('http://') ? redirectUrl.substr(7) : redirectUrl.substr(8)
        let parsed_host = hostname_withoutprotocol
          .split('/')[0]
          .split('.')
          .slice(-2)
          .join('.')
          .toLowerCase()

        if (config.secureDomains.includes(parsed_host)) {
          window.open(this.activeNotification.redirect_url, '_self')
        } else {
          console.error(this.activeNotification.redirect_url + ' is not a secure domain')
        }
      } else {
        this.isDetailsModalOpen = true
      }
    },

    getAppName(notification) {
      if (!notification.application_title) {
        if (!notification.application) {
          notification.application_title = 'Sistema'
        } else {
          for (let app of this.$store.getters.getApps) {
            if (app.code == notification.application) {
              notification.application_title = app.title
              break
            }
          }
          if (!notification.application_title) {
            notification.application_title = notification.application
          }
        }
      }

      return utils.appLabels()[notification.application_title]
        ? utils.appLabels()[notification.application_title]
        : notification.application_title
    },
    getAppIcon(notification) {
      if (!notification.application_icon) {
        notification.application_icon = 'default'
        if (notification.application) {
          for (let app of this.$store.getters.getApps) {
            if (app.code == notification.application) {
              if (app.icon) {
                notification.application_icon = app.icon.url.replace('{SIZE}', '65')
              }
              break
            }
          }
        }
      }
      return notification.application_icon
    },

    async update(notification, action) {
      const updatedNotification = await ServiceNotifications.update(notification.key, action)
      if (action != 'unarchive') {
        if (action != 'unread') {
          this.$store.dispatch('removeUnreadNotification', notification.key)
        }
        if (action == 'read' || action == 'accept') {
          notification.unread = false
          notification.date = updatedNotification.date
        } else if (action == 'unread') {
          notification.unread = true
          notification.date = null
        } else {
          notification.unread = false
          notification.date = updatedNotification.date
          notification.archived_date = updatedNotification.archived_date
        }
      }
      if (this.isDetailsModalOpen) {
        this.closeDetailsModal()
      }

      if ((action == 'archive' && this.view == 'inbox') || (action == 'unarchive' && this.view == 'archived')) {
        this.$emit('remove-from-list', notification.key)
      }
    },
  },
}
</script>

<style lang="scss">
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease-out;
}
.list-enter-active {
  opacity: 0.5;
}
.list-leave-to {
  transform: translateY(-300%);
  opacity: 0;
}
.list-leave-active {
  position: absolute;
}
</style>

<i18n>
{
  "pt": {
    "notification": "Notificação",
    "markAsRead": "Lida",
    "archive": "Arquivar",
    "moveToInbox": "Mover para inbox",
    "awaitsAcceptance": "Aguarda aceitação",
    "open": "Abrir",
    "noNotifications":"Sem notificações para apresentar.",
    "accept": "Aceitar",
    "close": "Fechar",
    "see": "Ver",
    "markAsUnread": "Não lida"
  },
  "en": {
    "notification": "Notification",
    "markAsRead": "Read",
    "archive": "Archive",
    "moveToInbox": "Move to inbox",
    "awaitsAcceptance": "Awaits acceptance",
    "open": "Open",
    "noNotifications":"No notifications",
    "accept": "Accept",
    "close": "Close",
    "see": "See",
    "markAsUnread": "Unread"
  }
}
</i18n>
