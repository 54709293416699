<template>
  <b-dropdown v-if="hasPermissions" aria-role="list" position="is-bottom-right">
    <fw-button-dropdown
      slot="trigger"
      type="xlight"
      class="flex flex-col"
      :label="isInManagment ? $t('managment') : $t('personal')"
      :upper-label="$t('area')"
    >
    </fw-button-dropdown>
    <b-dropdown-item
      class="flex gap-1 items-center font-medium"
      :label="$t('personal')"
      aria-role="listitem"
      :class="{ 'text-primary': !isInManagment }"
      @click.native="goToPublic"
    >
      {{ $t('personal') }}
    </b-dropdown-item>
    <b-dropdown-item
      :label="$t('managment')"
      aria-role="listitem"
      class="flex gap-1 items-center font-medium"
      :class="{ 'text-primary': isInManagment }"
      @click.native="goToManage"
    >
      {{ $t('managment') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    hasPermissions: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isInManagment() {
      return this.$route.name.includes('manage')
    },
  },

  methods: {
    goToPublic() {
      if (this.isInManagment) {
        this.$router.push({ name: 'home' })
      }
    },

    goToManage() {
      if (!this.isInManagment) {
        this.$router.push({ name: 'manage' })
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "personal": "Pessoal",
    "managment": "Gestão",
    "area": "Área"
  },
  "en": {
    "personal": "Personal",
    "managment": "Managment",
    "area": "Area"
  }
}
</i18n>
